import React, {useState, useEffect} from 'react'
import {NavLink, Link} from 'react-router-dom'
import './Navbar.scss'
import gsap, {Power3} from 'gsap'
import {ReactComponent as Logo} from '../images/Final Logion/Final.svg';

function Navbar(props) {

    useEffect(()=>{

        const logoPaths = document.querySelectorAll('.logo > svg > path');

        for(var i = 0; i < logoPaths.length; i++){
            logoPaths[i].style.fill = props.logoColor
        }

        const first = document.querySelector('.first-link');
        const second = document.querySelector('.second-link');
        const third = document.querySelector('.third-link');
        const fourth = document.querySelector('.fourth-link');
        const fifth = document.querySelector('.fifth-link');
        const sixth = document.querySelector('.sixth-link');

        first.addEventListener('mouseover', function(){
            first.classList.add('padding-left');
            second.classList.add('bg-pos');
            third.classList.add('bg-pos');
            fourth.classList.add('bg-pos');
            fifth.classList.add('bg-pos');
            sixth.classList.add('bg-pos');
        })

        first.addEventListener('mouseout', function(){
            first.classList.remove('padding-left');
            second.classList.remove('bg-pos');
            third.classList.remove('bg-pos');
            fourth.classList.remove('bg-pos');
            fifth.classList.remove('bg-pos');
            sixth.classList.remove('bg-pos');
        })

        second.addEventListener('mouseover', function(){
            first.classList.add('bg-pos');
            second.classList.add('padding-left');
            third.classList.add('bg-pos');
            fourth.classList.add('bg-pos');
            fifth.classList.add('bg-pos');
            sixth.classList.add('bg-pos');
        })

        second.addEventListener('mouseout', function(){
            first.classList.remove('bg-pos');
            second.classList.remove('padding-left');
            third.classList.remove('bg-pos');
            fourth.classList.remove('bg-pos');
            fifth.classList.remove('bg-pos');
            sixth.classList.remove('bg-pos');
        })

        third.addEventListener('mouseover', function(){
            first.classList.add('bg-pos');
            second.classList.add('bg-pos');
            third.classList.add('padding-left');
            fourth.classList.add('bg-pos');
            fifth.classList.add('bg-pos');
            sixth.classList.add('bg-pos');
        })

        third.addEventListener('mouseout', function(){
            first.classList.remove('bg-pos');
            second.classList.remove('bg-pos');
            third.classList.remove('padding-left');
            fourth.classList.remove('bg-pos');
            fifth.classList.remove('bg-pos');
            sixth.classList.remove('bg-pos');
        })

        fourth.addEventListener('mouseover', function(){
            first.classList.add('bg-pos');
            second.classList.add('bg-pos');
            third.classList.add('bg-pos');
            fourth.classList.add('padding-left');
            fifth.classList.add('bg-pos');
            sixth.classList.add('bg-pos');
        })

        fourth.addEventListener('mouseout', function(){
            first.classList.remove('bg-pos');
            second.classList.remove('bg-pos');
            third.classList.remove('bg-pos');
            fourth.classList.remove('padding-left');
            fifth.classList.remove('bg-pos');
            sixth.classList.remove('bg-pos');
        })

        fifth.addEventListener('mouseover', function(){
            first.classList.add('bg-pos');
            second.classList.add('bg-pos');
            third.classList.add('bg-pos');
            fourth.classList.add('bg-pos');
            fifth.classList.add('padding-left');
            sixth.classList.add('bg-pos');
        })

        fifth.addEventListener('mouseout', function(){
            first.classList.remove('bg-pos');
            second.classList.remove('bg-pos');
            third.classList.remove('bg-pos');
            fourth.classList.remove('bg-pos');
            fifth.classList.remove('padding-left');
            sixth.classList.remove('bg-pos');
        })

        sixth.addEventListener('mouseover', function(){
            first.classList.add('bg-pos');
            second.classList.add('bg-pos');
            third.classList.add('bg-pos');
            fourth.classList.add('bg-pos');
            fifth.classList.add('bg-pos');
            sixth.classList.add('padding-left');
        })

        sixth.addEventListener('mouseout', function(){
            first.classList.remove('bg-pos');
            second.classList.remove('bg-pos');
            third.classList.remove('bg-pos');
            fourth.classList.remove('bg-pos');
            fifth.classList.remove('bg-pos');
            sixth.classList.remove('padding-left');
        })
    })

    const [showMenu, setShowMenu] = useState(false);

    const overlay = document.getElementById('nav-overlay');

    // The function to chack if it is white or black

    var tl1 = gsap.timeline();
    var tl2 = gsap.timeline();

    // TL1

    function tl1new(){

        tl1.fromTo(overlay, {
            width: '0%',
            ease: Power3.easeOut
        }, {
            width: '100%',
            ease: Power3.easeOut
        }).fromTo('.span-cross', {
            opacity: 0
        }, {
            opacity: 1
        }).fromTo('.menu-inner .menu-item', {
            x: '-50px',
            opacity: 0,
            // ease: Power3.easeOut,
            stagger: 0.075
        },{
            opacity: 1,
            x: '0px',
            stagger: 0.075
        }, 0.25).fromTo('.menu-address__wrapper div',{
            opacity: 0,
            y: '30px',
            stagger: 0.1
        },{
            opacity: 1,
            y: '0px',
            stagger: 0.1
        }, 0.25).fromTo('.nav-inner__caption', {
            opacity: 0,
            y:'30px'
        },{
            opacity: 1,
            y: '0px'
        }, 0.25);


        gsap.to('.span-1',{
            opacity: 0
        })

        gsap.to('.span-3',{
            opacity: 0
        })

        gsap.to('.span-2',{
            opacity: 0,
        
     })
    }
   
      

    //  TL2

    function tl2new(){
        tl2.fromTo('.menu-address__wrapper div', {
            y: '0px',
            opacity: 1,
        }, {
            y: '30px',
            opacity: 0
        }, 0).fromTo('.menu-address__wrapper div', {
            y: '0px',
            opacity: 1,
            stagger: 0.075
        }, {
            y: '30px',
            opacity: 0
        },0).fromTo('.nav-inner__caption',{
            opacity: 1,
            y: '0px'
        }, {
            opacity: 0,
            y: '0px'
        }, 0).fromTo('.menu-inner .menu-item', {
            x: '0px',
            opacity: 1,
            stagger: 0.075
        }, {
            x: '-50px',
            opacity: 0,
            stagger: 0.075
        }, 0.2).fromTo('.span-cross',{
            opacity: 1
        }, {
            opacity: 0
        }, 0.2).fromTo(overlay, {
            width: '100%',
            ease: Power3.easeIn
        }, {
            width: '0%',
            ease: Power3.easeIn
        }, 0.17)


        gsap.to('.span-1',{
            opacity: 1
        })
    
        gsap.to('.span-3',{
            opacity: 1
        })
    
        gsap.to('.span-2',{
            opacity: 1,
            height: '1px'
        })
        
    }

    
       
    if(showMenu){
        
        gsap.to('.logo', {
            opacity: 0
        })
        tl1new();
    }

    if(!showMenu){
        gsap.to('.logo', {
            opacity: 1,
            delay: 0.5
        })
        tl2new();
    }

    return (
        <div id="navbar">
            <div id="nav-overlay">
                <div className="container">
                    <div className="nav-inner__wrapper">
                        <p className="nav-inner__caption">menu</p>
                    
                    <div className="menu">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="menu-inner">
                                    <div className="menu-item">
                                        <NavLink to="/" exact>
                                             <h1 className="first-link">Home</h1>
                                        </NavLink>
                                    </div>

                                    <div className="menu-item">
                                        <NavLink to="/projects" exact>
                                           <h1 className="second-link">Our Work</h1>
                                        </NavLink>
                                        
                                    </div>

                                    <div className="menu-item">
                                         <NavLink to="/services" exact>
                                            <h1 className="third-link">Services</h1>
                                         </NavLink>
                                        
                                    </div>

                                    <div className="menu-item">

                                        <NavLink to="/about" exact>
                                            <h1 className="fourth-link">About</h1>
                                        </NavLink>
                                       
                                    </div>

                                    <div className="menu-item">

                                        <NavLink to="/contact" exact>
                                            <h1 className="fifth-link">Contact</h1>
                                        </NavLink>
                                        
                                    </div>

                                    <div className="menu-item">
                                        <NavLink to="/blogs">
                                            <h1 className="sixth-link">Blogs</h1>
                                        </NavLink>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="menu-address">
                                    <div className="menu-address__wrapper">
                                        <div className="email-p">
                                            <a>
                                                <p>info@logionsolutions.com</p>
                                            </a>
                                            
                                        </div>

                                        <div className="tel-p">
                                            <p>+91-7358103456</p>
                                        </div>

                                        <div className="nav-address">
                                            <p>107, Sahajanand Arcade,<br/>Helmet Crossroads, Satellite<br/> Ahmedabad-380015</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="">

                <div className="nav-toggler__wrapper">
                    <div className="nav-toggler" >
                        <div onClick={() => setShowMenu(!showMenu)} style={{cursor: 'pointer'}}>
                            <span className="nav-toggler__span span-1" style={{backgroundColor: props.color}}></span>
                            <span className="nav-toggler__span span-2" style={{backgroundColor: props.color}}></span>
                            <span className="nav-toggler__span span-3"  style={{backgroundColor: props.color}}></span>
                            <span className="span-cross"></span>
                        </div>
 
                    </div>
                </div>

                <div className="frame">
                     
                    <div className="frame-inner">
                        
                        <Link to="/">
                            <div className="logo">     
                                <Logo/>
                            </div>
                        </Link>

                        <div className="header-links" >
                            <Link to="/contact">
                                <p style={{color: props.color}}>contact</p>
                            </Link>
                            
                        </div>
                       
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Navbar
