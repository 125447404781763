import React from 'react'
import ButtonWhite from './ButtonWhite'
import './PinkBanner.scss'
import {Link} from 'react-router-dom'

function PinkBanner() {
    return (
        <div>
            <div className="hww-banner">
                <div className="container">
                    <div className="hww-inner-wrapper">
                        <div className="hww-banner-content">
                            <p>Reach out to us</p>
                            <h2>Let’s work together to build something great.</h2>
                            <div className="btn-position">
                                <Link to="/contact">
                                    <ButtonWhite/>
                                </Link>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PinkBanner
