import React, { Component } from 'react'
import { ProjectData } from './Data';
import Navbar from './Navbar';
import PageHead from './PageHead';
import Banner from './Banner'
import gsap from 'gsap';
import './SingleProject.scss'
import Footer from './Footer';
import { Helmet } from 'react-helmet'

export default class SingleProject extends Component {
    
    componentDidMount(){
        gsap.set('.nav-overlay', {
            width: '0%'
        })
        gsap.fromTo('#home', {
            opacity: 0
          }, {
            opacity: 1
          })

          gsap.from('#single-project', {
             opacity: 0 
          })

          
        gsap.timeline().from('#page-head .line h2', {
            y: '120px',
            skewY: 7,
            stagger: {
                amount: 0.3
            }
        }).from("#page-head .innerHero__caption", {
            opacity: 0
        }, 0.5).to('.ih-banner__wrapper', {
            opacity: 1            
        })

    }
    
    render() {

        let getName = this.props.match.params.name;
       
        let getData = ProjectData.mainData.find(function(e){
            if(e.name === getName){
                return e
            }

            else return false
          });
       
        return (
            <div id="single-project">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content={getData.description}/>
                    <meta property="og:title" content={getData.titleMain}/>
                    <meta property="og:description" content={getData.description}/>
                    <meta name="keywords" content={getData.keywords}/>
                    <title>{getData.titleMain}</title>
                </Helmet>
                <Navbar color="#000" logoColor="#000"/>
                <div className="container">
                     <PageHead caption={getData.caption} titleL1={getData.titleL1} titleL2={getData.titleL2}/>
                </div>
                <Banner imgUrl={getData.bannerImg}/>

                <div className="container">
                    <div className="overview">
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <div className="overview-inner">
                                    <h1>{getData.overviewTitle}</h1>
                                    <p>
                                    {getData.brief}
                                    </p>
                                    <ul>
                                        <li>{getData.briefList1}</li>
                                        <li>{getData.briefList2}</li>
                                        <li>{getData.briefList3}</li>
                                        <li>{getData.briefList4}</li>
                                        <li>{getData.briefList5}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-proj-img">
                        <img src={getData.gif} alt="" />
                    </div>

                    <div className="challenge">
                        <div className="row">
                            <div className="col-12 col-md-8">
                                <div className="challenge-inner">
                                    <h1>Project Experience</h1>
                                    <p>
                                     {getData.challengeBrief}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-proj-imgs">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="img-wrapper">
                                    <img src={getData.lastImage1} alt="" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 ">
                                <div className="img-wrapper right">
                                    <img src={getData.lastImage2} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer/>
            </div>
        )
    }
}
