import React, {useEffect} from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import PageHead from '../Components/PageHead'
import BlogsMain from '../Components/BlogsMain'
import gsap , { Power3} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {BlogsFront} from '../Components/Data'
import {Helmet} from 'react-helmet'

function Blogs() {

    gsap.registerPlugin(ScrollTrigger);

    useEffect(()=>{

      gsap.fromTo('#blogs', {
        opacity: 0
      }, {
        opacity: 1
      }, 0.3)

        const rightsBlogs = gsap.utils.toArray('.right-blog') 

          gsap.timeline().from('#page-head .line h2', {
            y: '120px',
            skewY: 7,
            stagger: {
                amount: 0.3
            }
            }).from("#page-head .innerHero__caption", {
              opacity: 0
          }, 0.5)

          ScrollTrigger.matchMedia({
            "(min-width: 1200px)": function(){
              rightsBlogs.forEach(right=>{
            
                gsap.fromTo(right, {
                  y: '150px',
                  scrollTrigger: {
                    trigger: '.blogs-main',
                    scroller: '#root',
                    top: 'top 200px',
                    scrub: 1.2,
                    
                  }
                }, {
                    y: '-100px',
                    scrollTrigger: {
                        trigger: '.blogs-main',
                        scroller: '#root',
                        top: 'top 200px',
                        scrub: 1.2,
                        
                      }
                })
            })
            }
          })

        

        gsap.timeline({
            scrollTrigger:{
              trigger: '.HB-wrapper',
              scroller: '#root',
              start: 'top 100%',
              end: 'top 100%',
            }
           
          }).from('.HB-inner__img', {
            opacity: 0,
            scale: 1.1,
            duration: 0.1,
            delay: 0.5
          }).to('.HB-inner__img > img', {
            opacity: 1,
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            ease: Power3.easeOut
          }).from('.HB-main__content h2',{
            opacity: 0,
            skewY: -7
          }).from('.HB-main__content p',{
            opacity: 0
          })

          gsap.timeline({
            scrollTrigger:{
              trigger: '#footer',
              start: 'top',
              end: 'top',
              scroller: '#root',
              scrub: 1
            }
          }).to('.logo svg path',{
            fill: '#fff'
          }, 0).to('.header-links a p', {
            color: "#fff"
          }, 0).to('.nav-toggler span', {
            backgroundColor: "#fff"
          }, 0)
    })
    
    return (
        <div id="blogs">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Passionate about making wonders happen on screen. Developing Websites and Applications for client’s as if it’s our own."/>
                <meta property="og:title" content="Logion Solutions | Blogs | Digital Agency in Ahmedabad, Gujarat | Digital Design, UIUX, Responsive Web Design" />
                <meta property="og:description" content="Logion Solutions is an award winning website development company that specializes in high end website development. Check out our services here"/>
                <meta name="keywords" content="Logion, web and app development services, web and mobile app services, web services rest api, wordpress, developers ahmedabad, arbre, branding, marketing firms, social media marketing, about us, story, journey, information technology"/>
                <title>Blogs | Our Journey | Our Team | top flutter apps | WebGl | three.js | e-commerce Development | Branding | ERP | Content Writing | Social Media Marketing  </title>
            </Helmet>

            <Navbar color="#000" logoColor="#000"/>
            
            <div className="container">
                <PageHead caption="Insights" titleL1 = "Check Out" titleL2 = "Our Blogs"/>
                <BlogsMain link1="/blogs/flutter" link2="/blogs/frontend" image1={BlogsFront.image4} title1={BlogsFront.title4} caption1={BlogsFront.caption4}  image2={BlogsFront.image1} title2={BlogsFront.title1} caption2={BlogsFront.caption1}/>
                <BlogsMain link1="/blogs/takeovertheworld" link2="/blogs/webgl" image1={BlogsFront.image2} title1={BlogsFront.title3} caption1={BlogsFront.caption3}  image2={BlogsFront.image3} title2={BlogsFront.title2} caption2={BlogsFront.caption2}/>
            </div>

            <Footer/>
        </div>
    )
}

export default Blogs
