import React from 'react'
import './HomeHero.scss'
import Plus1 from '../images/Final PLus/1.png'
import Plus2 from '../images/Final PLus/5.png'
import {ReactComponent as Logion} from '../images/Logion/logion.svg'
import {ReactComponent as Circle} from '../images/Circle/Circle.svg'

function HomeHero() {
    return (
        <div id="home-hero">
            <div className="plus1">
                <img src={Plus1} alt="" />
            </div>

            <div className="plus2">
                <img src={Plus2} alt="" />
            </div>

            <div className="logion-ver">
                <Logion/>
            </div>

            <div className="logion-circle">
                <Circle/>
            </div>
            <div className="container-custom">
                <div className="hero__inner-wrapper">
                    <div className="hero__inner-content">   
                        <div className="line">
                             <h1>Websites</h1>
                        </div>   
                        <div className="line">
                            <h1>Apps</h1>
                        </div>   
                        <div className="line">
                            <h1>Branding</h1>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeHero
