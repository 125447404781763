import nb from '../images/NB.webp'
import kasper from '../images/kasper.webp'
import arbre from '../images/Arbre 6.webp'
import oneDegree from '../images/oneDegree.webp'
import isugar from '../images/Isugar.webp'
import marshal from '../images/marshal.webp'
import dela from '../images/Dela.webp'
import wajooba from '../images/Wajooba.webp'

import flutterBanner from '../images/newImages/flutterBanner.webp'
import webglBanner from '../images/newImages/webglBanner.webp'
import frontendBanner from '../images/newImages/frontendBanner.webp'
import digitalmarketingBanner from '../images/newImages/digitalmarketingBanner.webp'

import ServicesBanner from '../images/MainPageBanners/ServicesBanner.webp'
import AboutBanner from '../images/MainPageBanners/AboutBanner.webp'

import oneDegreeBanner from '../images/Full Width Webp/1 Degree full.webp'
import oneDLastimage1 from '../images/newImages/1 Degree 1.webp'
import oneDLastimage2 from '../images/newImages/1 Degree 2.webp'
import oneDgif from '../images/Full Width Webp/1 Degree.webp'

import Frontend from '../images/Blog Original/Frontend.webp'
import Digital from '../images/Blog Original/Digital.webp'
import WebGL from '../images/Blog Original/WebGL.webp'
import Flutter from '../images/Blog Original/Flutter.webp'

import nbBanner from '../images/Full Width Webp/NB.webp'
import nbLastimage1 from '../images/newImages/nurseryBazzar1.webp'
import nbLastimage2 from '../images/newImages/nurseryBazzar2.webp'
import nbgif from '../images/Full Width Webp/nb2.webp'

import arbreBanner from '../images/Full Width Webp/arbre full.webp'
import arbreLastimage1 from '../images/Work Screenshots Final/arbre.webp'
import arbreLastimage2 from '../images/Work Screenshots Final/arbre 2.webp'
import arbregif from '../images/Full Width Webp/arbre.webp'

import kasperBanner from '../images/Full Width Webp/kbanner.webp'
import kasperLastimage1 from '../images/Work Screenshots Final/kasper 1.webp'
import kasperLastimage2 from '../images/Work Screenshots Final/kasper 2.webp'
import kaspergif from '../images/Full Width Webp/Kasper.webp'

import isugarBanner from '../images/Full Width Webp/sugar.webp'
import IsugarLastimage1 from '../images/Work Screenshots Final/Isugar.webp'
import IsugarLastimage2 from '../images/Work Screenshots Final/Isugar 2.webp'
import sugargif from '../images/Full Width Webp/sugar.webp'

import marshalBanner from '../images/Full Width Webp/MarshalBanner.webp'
import marshalLastimage1 from '../images/Work Screenshots Final/Marshal 1.webp'
import marshalLastimage2 from '../images/Work Screenshots Final/Marshal 2.webp'
import marshalgif from '../images/Full Width Webp/Marshal.webp'

import wajoobaBanner from '../images/wajooba full width.webp'
import wajoobaLastimage1 from '../images/Work Screenshots Final/wajooba 1.webp'
import wajoobaLastimage2 from '../images/Work Screenshots Final/wajooba 2.webp'
import wajoobagif from '../images/Full Width Webp/wajooba.webp'

import delaBanner from '../images/dela full width.webp'
import delaLastimage1 from '../images/Work Screenshots Final/dela 1.webp'
import delaLastimage2 from '../images/Work Screenshots Final/dela 2.webp'
import delagif from '../images/Full Width Webp/dela.webp'

export const BlogsFront = {
    image1: Frontend,
    image2: Digital,
    image3: WebGL,
    image4: Flutter,

    link1: '/blogs/frontend',
    link2: '/blogs/webgl',
    link3: '/blogs/takeovertheworld',
    link4: '/blogs/flutter',
    

    title1: 'Front-end Developer',
    title2: 'WebGL',
    title3: 'Digital Marketing',
    title4: 'Flutter Future',

    caption1: 'How to be a great FE developer',
    caption2: 'Flash is gone!',
    caption3: '3 seconds to take over the world',
    caption4: 'Top apps made using Flutter',
}

export const ProjectsData = {
    image1: nb,
    workTitle1: 'Online Nursery',
    workCaption1: 'e-commerce',

    image2: kasper,
    workTitle2: 'Meet Kasper',
    workCaption2: 'Branding',

    image3: oneDegree,
    workTitle3: 'Trekking Firm',
    workCaption3: 'e-commerce',

    image4: arbre,
    workTitle4: 'Arbre Cafe',
    workCaption4: 'Branding',

    image5: isugar,
    workTitle5: 'Shoes and Socks',
    workCaption5: 'e-commerce',

    image6: marshal,
    workTitle6: 'Marshal Training',
    workCaption6: 'Branding',

    image7: dela,
    workTitle7: 'Electronics',
    workCaption7: 'e-commerce',

    image8: wajooba,
    workTitle8: 'Wajooba',
    workCaption8: 'Educational Platform',
}

export const HomeWork_Home = {
    image: nb,
    workTitle: 'Plants and Garden',
    workCaption: 'Horticulture e-commerce',

    image2: kasper,
    workTitle2: 'Meet Kasper',
    workCaption2: 'Corporate website',

    image3: arbre,
    workTitle3: 'Arbre Cafe',
    workCaption3: 'Branding Website',

    image4: oneDegree,
    workTitle4: 'Trekking Firm',
    workCaption4: 'e-commerce',

}

export const ServicesData = {
    
    caption: 'What we do',
    titleL1: 'Explore Beyond',
    titleL2: 'The Ordinary',

    bannerImage: ServicesBanner
}

export const AboutData = {

    bannerImage: AboutBanner,

    title1: 'Who We Are',
    content1: 'From a cricketer to a musician, or an engineer to a management guy, we all came together with the same purpose and passion of creating digital experiences that leave a mark on the people using our products.',
    title2: 'Peeps who Understand The Business',
    content2: 'Our diverse team of professionals is made up of CMS developers, graphic designers, content writers and coders. We focus on generating business for our clients and believe that our success lies in our clients’ success.',
    title3: 'Engaging our Creative Genes',
    content3: 'We believe in pushing our limits and creating designs & features that get results for your website & applications. We continuously & creatively work to identify new technologies that help our clients meet their needs better.',
    title4: 'Making It A Fun Experience',
    content4: 'Technology can be daunting, but we brighten the process by shaking things up and sticking to a positive, healthy approach that helps educate our clients and keeps things on track.',
    title5: 'Our Philosophy',
    content5: 'At Logion, we have cultivated our reputation as a notable website & application development agency not just by creating effective designs for our clients, but also by letting empathy drive our work.',
}

export const ProjectData = {
    mainData: [{
        id: 1,
        name: 'plants',
        title: 'Nursery Bazzar',
        caption: 'Horticulture e-commerce',
        titleL1: 'Deliver Plants',
        titleL2: 'to your doorstep',

        bannerImg: nbBanner,

        overviewTitle: 'Overview',
        brief: 'The company is a start-up focusing on taking the concept of nursery online, especially for gifting purposes. Gujarat based entrepreneur hired Logion Solutions for the purpose of redesigning Nursery bazzar to revamp it’s website and bring it to international standards for streamlining the B2C e-commerce alongside import and export of plants. ',
        briefList1: 'Website Design',
        briefList2: 'Website Development',
        briefList3: 'UI/UX',
        briefList4: 'CMS',
        briefList5: 'email marketing',

        gif: nbgif,

        challengeBrief: 'The project for us was a bit of a challenge as it involved developing complex plugins and a flexible timeless theme as per the clients’ requirements. As we spent time learning about the horticulture market and our clients’ companies it became clear to as what path we need to take and executed the strategies accordingly. First step was to design an outstanding UI as per to the international standards of e-commerce using the brand colour accents and typography. Design took about 12 days and after the completion we started the development process which was tricky as the client had very specific requirements in terms of functionality. Finally, we delivered an outstanding platform after rigorous testing and R&D, within 60 days',

        lastImage1: nbLastimage1,
        lastImage2: nbLastimage2,

        nextLink: '/projects/oneDegree'

    }, 
    {
        id: 2,
        name: 'oneDegree',
        title: 'Trekking Company',

        caption: 'Trek booking site',
        titleL1: 'Climb your mountain',
        titleL2: 'with us',

        bannerImg: oneDegreeBanner,

        overviewTitle: 'Overview',
        brief: 'Trekking firm specializing in organising and guiding mountain climbing adventures. Skilled in the practicalities of developing dreams into reality. India based lawyer, with a passion for trekking hired Logion solutions to develop a booking website for them with the sole purpose of sharing the trekking experience with people all over the world.',
        briefList1: 'Website Design & Development',
        briefList2: 'UI/UX',
        briefList3: 'CMS',
        briefList4: 'WooCommerce',
        briefList5: 'Three.js',

        gif: oneDgif,

        challengeBrief: 'Setting the tone of the website was a challenging part for us, as we went through a lot of discussions and brainstorming on what would be the perfect layout and theme for the website. For an e-commerce usability and functionality is prioritized over design although the client wanted it the other way round. He wanted the website to be an experience in itself like his treks. We knew that we would be using graphics heavily when we got to the design phase, hence the layout and placement of graphics was a very important factor, keeping in mind the website flow. Balancing just enough imagery would be key to showing value while creating intrigue and prompting potential trekkers to contact the client.To ensure an immersive visual experience, we developed interactive panorama with the help of Three.js. An intuitive CMS was built out to give the client the ability to make quick and easy updates to their site in the future.',

        lastImage1: oneDLastimage1,
        lastImage2: oneDLastimage2
    },
    {
        id: 3,
        name: 'arbre',
        title: 'Arbre Restro Cafe',

        caption: 'Branding Site',
        titleL1: 'Dine Amongst',
        titleL2: 'the Nature at Arbre',

        bannerImg: arbreBanner,

        overviewTitle: 'Overview',
        brief: 'A restro cafe based in India, delivering dining experience like never before i.e., dine amongst the nature. An overnight sensation due to social media put faith in the power of Digital presence, the owner hired Logion Solutions to design and develop a website for the café.',
        briefList1: 'Website Design',
        briefList2: 'UI/UX',
        briefList3: 'CMS',
        briefList4: 'Custom Theme',
        briefList5: 'Network Security',

        gif: arbregif,

        challengeBrief: 'The R&D process was a very important first step in achieving a successful design of Arbre’s website. To ensure the site will be an accurate reflection and representation of the brand, we conducted an in-depth analysis of the hospitality industry & market, interviewed their team and to identify the taste & priorities for the design. Being a brand-new sensation, the most important aspect to prioritize in the strategic planning of the design was to take the look of the café and incorporate it on the website. Client had established branding in place when we started working with them, which meant it was our job to incorporate their colours, typography, and image styles throughout the design. During the design phase, we brought the wireframes to life by defining how these visual styling elements worked together to create a cohesive and appetizing UI design. Paired with an easy-to-use CMS, we also programmed the site the latest responsive technology to ensure that our goal to produce an optimized user experience came to fruition.',

        lastImage1: arbreLastimage1,
        lastImage2: arbreLastimage2
    },

    {
        id: 4,
        name: 'sugarEcomm',
        title: 'ISugar',

        caption: 'an e-commerce',
        titleL1: 'Go Vocal',
        titleL2: 'for Local ',

        bannerImg: isugarBanner,

        overviewTitle: 'Overview',
        brief: 'A start-up with the sole purpose of promoting products manufactured locally. Sharing the same ideology, the proprietor hired logion solutions to design and develop a platform that would promote local products internationally.',
        briefList1: 'Design',
        briefList2: 'Branding',
        briefList3: 'e-commerce',
        briefList4: 'CMS',
        briefList5: 'Social Media Management',

        gif: sugargif,

        challengeBrief: 'Being a new company, the client required a website that would reflect their joyful and upbeat brand. Client was honed in on driving the customer base in India to local brands. During our meeting with them, we discussed their needs and goal of becoming THE brand that promotes local fashion & apparel product manufacturers in India. Pulling from UX patterns we observed during our research phase, we structured the site in a way that would encourage the user to browse through the website and displayed the products in a manner that would push users to make a purchase. We integrated our custom designs for the website with the Wordpress CMS to make it easy for the client to manage all of the products and optimize them for organic SEO.',

        lastImage1: IsugarLastimage1,
        lastImage2: IsugarLastimage2,
    }
    ,
    {
        id: 5,
        name: 'marshalTraining',
        title: 'Marshal Training',

        caption: 'Corporate Site',
        titleL1: 'You won’t look elsewhere',
        titleL2: "after Marshal Training",

        titleMain: 'Marshal Training',

        bannerImg: marshalBanner,

        overviewTitle: 'Overview',
        brief: 'A Dubai based company, offering Health and Safety consultancy specializing in delivering training and inspection services, hired Logion Solutions to re-develop their website to get their brand a fresh digital presence and in turn generating leads.',
        briefList1: 'Website Design',
        briefList2: 'Content Writing',
        briefList3: 'CMS',
        briefList4: 'Web Development',
        briefList5: 'Illustrations',

        gif: marshalgif,

        challengeBrief: 'Client came to us with a website that was outdated, wordy and stale. There was the fear that their current website failed to clearly explain what they do in a way that allowed visitors to understand the value their firm provides. They wanted to reshape the perceptions of their company online with an informative website that was pleasing to the eye and enjoyable to view. In order to provide them with a focused and attractive website, we spent time with their team defining key goals and action items for the new site. Once we architected, tested, and approved the framework, we started building out the new website. In order to give the site a modern look, we utilized a harmonious balance of whitespace, striking visual imagery, and typographical styling to accentuate the value propositions offered by RDN.',

        lastImage1: marshalLastimage1,
        lastImage2: marshalLastimage2
    }, 
    {
        id: 6,
        name: 'kasper',
        title: 'Meet Kasper',

        caption: 'Corporate site',
        titleL1: 'The dental practice',
        titleL2: 'management solution.',

        bannerImg: kasperBanner,

        overviewTitle: 'Overview',
        brief: 'Meet Kasper focuses on providing management solutions to dental practises all across the United States. The client hired Logion Solutions to increase their brand awareness by creating a digital presence, in turn generating leads and driving business in.',
        briefList1: 'Web Design',
        briefList2: 'Figma',
        briefList3: 'Elementor',
        briefList4: 'CMS',
        briefList5: 'Web Development',

        gif: kaspergif,

        challengeBrief: 'Having failed to establish a website sooner to keep up with digital demands, The client was feeling the impact it was having on staying ahead of the competition. The main goal for us was to establish a modern and up-to-date look and feel of the site all throughout the pages. After thorough research into the dental industry as well as the ERP industry we designed a well put layout accentuating the client’s cutting edge dental ERP system features. Designed to the pixel on Figma and developed with the help of custom designed Wordpress theme integrated with Elementor pro, we delivered the website to the client within a span of 15 days.',

        lastImage1: kasperLastimage1,
        lastImage2: kasperLastimage2
    },

    {
        id: 7,
        name: 'wajooba',
        title: 'Wajooba',

        caption: 'a platform to host online classes',
        titleL1: 'Grow Your Knowledge',
        titleL2: 'with Wajooba',

        bannerImg: wajoobaBanner,

        overviewTitle: 'Overview',
        brief: 'A digital platform provider to training businesses with the latest technologies to help them Automate tasks and get them more enrolments. The client’s previous website had gone out of style, a pretty basic one to be precise, hence they hired Logion Solutions to redevelop their website according to the latest trends.',
        briefList1: 'Illustrations',
        briefList2: 'Angular',
        briefList3: 'API',
        briefList4: 'Web Development',
        briefList5: 'UI/UX',

        gif: wajoobagif,

        challengeBrief: 'Client came to us with a well-formed idea of changes that they wanted to see on their website. They had already invested a lot of time creating wireframes with another agency that captured their vision for the site, but did not want to continue working with them for the design and development phase of the site for various reasons. However, even though we were able to kick off the discovery phase with a lot of the strategy outlined, there was still a lot of learning that had to be done. We utilized our time digging into the core of who the client was, how their site operated, pain points with the old site, and learning about their target audience. After doing a thorough research of the client and their industry, we came up with the perfect design to fit their brand. Understanding client’s needs, industry and the competition helped us deliver the project on point on time.',

        lastImage1: wajoobaLastimage1,
        lastImage2: wajoobaLastimage2
    }, 
    {
        id: 8,
        name: 'dela',
        title: 'Dela Electronics',

        caption: 'electronics e-commerce',
        titleL1: 'Quality Electronics',
        titleL2: 'at your doorstep',

        bannerImg: delaBanner,

        overviewTitle: 'Overview',
        brief: 'An electronics super store based out of Antigua, specializing in computer electronics, hired Logion Solutions to redevelop their website to give it more functionality and drive in more traffic and sales as their previous website, an e-commerce focused more on the content.',
        briefList1: 'Design',
        briefList2: 'e-commerce',
        briefList3: 'Web Development',
        briefList4: 'Custom Plugins',
        briefList5: 'CMS',

        gif: delagif,

        challengeBrief: 'While evaluating client’s previous website, it was apparent that the site was not a fair representation of the cutting-edge products they had to offer. We found that despite them being the largest store of computer electronics in their country, the website design they had was described as outdated, boring, and un engaging.Our approach during the design phase was to craft a fully functional e-commerce, yet simplistic website that caters to each of its users while also remaining engaging. While developing we wanted to demonstrate client’s products with an equally innovative site that reinstates the dominance the client has in the electronics industry, all while simply and directly presenting the visitor with what they’re looking for. Our approach during the design phase was to craft a fully functional e-commerce, yet simplistic website that caters to each of its users while also remaining engaging. While developing we wanted to demonstrate client’s products with an equally innovative site that reinstates the dominance the client has in the electronics industry, all while simply and directly presenting the visitor with what they’re looking for.',

        lastImage1: delaLastimage1,
        lastImage2: delaLastimage2
    }


],
}

export const BlogData = {
    mainData: [{
        id: 1,
        name: 'flutter',
        caption: "Google's Flutter",
        titleL1: "Top 3 Apps",
        titleL2: 'Built using Flutter',

        bannerImg: flutterBanner,

        titleMain: 'Top 3 Apps Built with Flutter',
        keywords: "Flutter, Flutter App Developers, flutter app development companies, flutter applications, flutter apps, Flutter Framework, google flutter, top flutter apps",
        description: "Here’s some top apps developed using Flutter, and those that reckon Flutter app development provides many benefits have more reasons to support their claim.",

        paraOneTitle: 'An Overview of Flutter',
        paraOneContent: 'Flutter is a cross-platform app development framework that comes with accessible native features and SDK. Developed by Google, Flutter is basically a UI toolkit used to build applications for multiple platforms, due to these exclusive capabilities Flutter is a leader in app development scenarios. It is based on the DART programming language and works just like an Android SDK which is based on Kotlin or Java programming languages.',

        paraTwoTitle: 'What makes Flutter stand out?',
        paraTwoContent: 
        <span>Flutter provides a fast way to build creative apps for both iOS and Android mobile platforms. Many among the top mobile app development companies are adopting Flutter but it isn’t surprising. What’s more surprising is that the Flutter framework is extensively used to develop Flutter apps for Google’s new operating system Fuchsia. Here are some of the top reasons which make Flutter awesome:
        
            <ul className="parent-ul">
                <li>Flutter allows app developers to code once, and run on multiple platforms.</li>
                <li>Faster development in minutes using fully customizable widgets available.</li>
                <li>Hot reload: Mobile app developers don’t have to wait for a long time anymore for the code changes to reflect on their screen. With the Hot Reload feature, they can render and see the changes in seconds!</li>
                <li>Easy to learn.</li>
                <li>Flutter app development provides a one-stop solution to develop & deploy apps.</li>
                <li>Flutter’s architecture allows for full customization that helps to develop expressive and flexible UI for the apps.</li>
            </ul>

        </span>,

        paraThreeTitle: 'Let us have a look at the top 3 Flutter apps in the market',
        paraThreeContent: 
        <ul>
            <li>Google Ads 2</li>
            <li>Xianyu</li>
            <li>Reflectly</li>
        </ul> ,
       
        
        paraFourTitle: 'Google Ads',
        paraFourContent: 'Google Ads is Google’s advertising system that allows businesses wanting to advertise on the Google search engine to do so by placing bids for certain ‘keywords’ for which the user’s advertisement, service offerings, product listings, or videos are shown on the top of the SERPs (Search Engine Result Pages).This helps people advertise on digital platforms to easily increase the audience to reach. Google Ads are Google’s top revenue generating system. And this is possible due to the Flutter framework. Google used the Flutter packages to configure the Firebase AdMob plugin to implement Google Ads.',

        paraFiveTitle: 'Xianyu',
        paraFiveContent: 'Alibaba, the largest eCommerce platform in the world, leveraged the power of Flutter framework into its shopping platform. Using Flutter, Alibaba was able to create one of the fast, elegant, and flawless platforms- Xianyu. With more than 200 million users worldwide, Xianyu is quite the mobile app! It helps users buy and sell millions of products from a large range of categories. Xianyu app incorporated Flutter with its own custom Xianyu APK for both Android and iOS platforms to provide a seamless experience.',

        paraSixTitle: 'Reflectly',
        paraSixContent: 'Reflectly was originally developed using React Native in 2017. But due to huge cross-platform compatibility issues in Android & iOS, Reflectly was completely migrated to the Flutter framework. Using Flutter, experienced mobile app developers were able to quickly build prototypes and test faster. Not only did the crash rates go down significantly, their users were happier than ever. With Flutter, app developers were able to use a single codebase for both Android and iOS; as a result, mobile app development didn’t require platform-specific implementations anymore.',

        paraSevenTitle: 'Watermaniac',
        paraSevenContent: 'A simple app built using Flutter allows the user to keep track of their daily water intake. The app developers loved working with Flutter. It allowed them to build simple and interactive UI that enhanced the user experience.',

        paraEightTitle: 'Pawfect Match',
        paraEightContent: 'Another interesting app built by using Flutter is Pawfect Match. This Flutter app helps people across the UK to adopt homeless animals. Using a wide range of widgets available in the Flutter framework, the flutter app developers were able to create a clean & interactive user interface. The users just have to customize their option for the selection process, which was hassle-free, thanks to Flutter.',

        paraNineTitle: 'Top Goals',
        paraNineContent: 'Built using Flutter, Top Goals is an amazing mobile app used by millions of football lovers around the world. The users are not only able to customize their feed, but they get live news updates about their favorite players and teams. With the help of Flutter Framework, app developers were able to create a clean and intuitive UI that helped users to easily use the app. The flutter App Developers who built this app absolutely enjoyed coding in Flutter. Not only were they able to fully customize the app but it took them less time to launch the app and roll out quick updates.',

        paraTenTitle: 'AppTree',
        paraTenContent: 'Another great example of a Flutter app is AppTree, an enterprise app. It enables the user to keep track of their business in one place. Business functionalities such as keeping track of employee attendance, audits, data collection, inspections, requests, workflow, and approvals are included in this app. Even the UI/UX design of this app is amazing. By using Flutter, the developers were able to create a highly interactive UI which the users find easy to use.',

        paraElevenTitle: 'HuYu',
        paraElevenContent: 'Huyu is a survey app in which the users share their shopping data and scan grocery receipts for incentives. Flutter played a great role in the development of this app. It took only 16 weeks to launch the app. Since Flutter renders everything itself, the flutter app developers were able to build the best UX/UI without compromise. The developers were able to seamlessly implement animation into the app which otherwise would have given the app developers a hard time supporting older system versions, and in addition to that using Flutter, playing around with animation wasn’t an issue!',

        paraTwelveTitle: 'Hamilton',
        paraTwelveContent: 'The official app of the hit New York Broadway musical- Hamilton is a great app for fans to use. With show related news, videos, trivial games to play and buy merchandise- users love using the Hamilton app. Flutter played an integral role in the development of this great app. The developers enjoyed using Flutter. They found it 20x times better than native app development! Additionally, with the Hot Reload feature, the developers were able to streamline design review and tune the UI to perfection.',

        paraThirteenTitle: 'PostMuse',
        paraThirteenContent: 'Developed using Flutter, PostMuse is a photo editor app that helps users to edit photos they upload on Instagram. With rich-features like fonts, images, photo frames, and emojis to select, users can unleash their creativity and customize their digital content. With no compromise on app performance, Flutter enhanced the usability and design of this app and allowed them to quickly enter the app market soon after development. This sums up the list of the top 10 apps built using Flutter. With instant reload, easy to read & code, and many other first-class features developing apps using Flutter is a magical experience for even the app developers.'
    }, 
    {
        id: 3,
        name: 'webgl',
        caption: 'WebGL over Flash',
        titleL1: "Flash is dead...",
        titleL2: "Long live WebGL",

        bannerImg: webglBanner,

        titleMain: 'Flash Is Dead … Long Live WebGL',
        keywords: "flash, html5, webgl, mobile, responsive, rendering, render, android",
        description: "For the past decade Flash has been regarded as the de facto standard for deploying rich graphics and multimedia on the web. Flash has indeed done a lot for the web as an interactivity and entertainment platform, but recent years have shown that it is time to move on. The future is HTML5, and with…",

        paraOneTitle: "Overview",
        paraOneContent: 
        <p>
            For the past decade Flash has been regarded as the de facto standard for deploying rich graphics and multimedia on the web. Flash has indeed done a lot for the web as an interactivity and entertainment platform, but recent years have shown that it is time to move on.
        </p>,

        paraTwoTitle: "The future",
        paraTwoContent: 
        <p>The future is HTML5, and with the WebGL standard for high-end HTML5 graphics entering the scene, the landscape is about to change dramatically. Steve Jobs initiated the transition with his open letter on Flash in 2010 (available here), concluding: 
        <br/><br/>
        “New open standards created in the mobile era, such as HTML5, will win on mobile devices (and PCs too).” He is indeed about to be right. Again.
        <br/><br/>
        In fact, our recent research shows that WebGL is now supported on more devices than Flash. It’s the bigger platform. In fact, WebGL powered HTML5 is now the largest platform on the planet for interactive browser graphics. And considering that WebGL also can be packaged into native apps and run independent of a browser on both desktop and mobile, the device reach is even wider.
        </p>,

        paraThreeTitle: "And so,",
        paraThreeContent:
        <p>
            To arrive at this conclusion, we have combined WebGL support numbers from real life devices tracked by webglstats.com, including sites like Youtube.com, with estimated global device volumes. Since Flash is not supported on mobile browsers out of the box, Flash primarily reaches Mac and PC desktop browsers, which we estimate to be about 1.81 billion in total.            <br/><br/>
            WebGL support is not fully developed on some device types but the recent fast adoption on Android with its more than 1 billion devices active globally leads us to believe that WebGL now is supported on more than 1.88 billion devices. Relatively speaking, 70 million is not a large gap, but since WebGL support is growing and Flash is retracting, it’s a very important milestone for the Internet ecosystem, highlighting the ongoing shift to open web standards such as HTML5.
        </p>,

        paraFourTitle: "Benefits",
        paraFourContent: 
        <p>
            Firstly, HTML5 is an integral part of the web. Since the web is HTML, interactive graphics on HTML5/WebGL blend right in. It’s all open JavaScript and fully integrated with any web site. Flash is a closed platform, requires installation of a plug-in, and as such is more sealed off from the website it’s published on.
            <br/><br/>
            Secondly, the graphics capabilities of WebGL are game changing, with 3D and most of the richness from high-end games and interactive multimedia available. WebGL enables access to hardware-accelerated graphics, and we would argue that WebGL is the largest leap in capability in the history of the web. The possibilities are huge and the applications stretch over multiple areas, such as web games, online advertising, e-learning and virtual reality.
            <br/><br/>
            Recently, Facebook announced the acquisition of Oculus Rift, the VR headset, and by leveraging new web technologies such as WebGL for 3D visualizations, that marriage can very well mean fundamentally new ways of experiencing the social web.
            <br/><br/>
            Thirdly, it runs on mobile. WebGL support has been behind on mobile but recent months have shown a massive increase in mobile support with Android leading the way. In fact, it goes beyond mobile too. WebGL powered HTML5 is about to be rolled out on many more types of units, including smart TVs, set-top boxes and gaming consoles over time.

        </p>,

        paraFiveTitle: "Conclusion",
        paraFiveContent: <p>All in all, it’s clear that the transition from Flash to HTML5 has now really gained momentum and what Steve Jobs foresaw in 2010 is now not only a theory, but is happening in reality too.
        </p>,
    },
    
    {
        id: 2,
        name: 'frontend',
        caption: 'Develop your Imagination',
        titleL1: "Be an Outstanding",
        titleL2: "Front-end Developer",

        bannerImg: frontendBanner,

        titleMain: 'How To stay focused as a self-taught front-end web developer',
        keywords: "front-end developer, website, design, layout, html, css",
        description: "Obviously! Resources for frontend web developer are available in abundance on internet. If you are just starting, you can start with html and CSS and then catch-up with Html5 and CSS3. However, these…",

        paraOneTitle: "Overview",
        paraOneContent: 
        <span>Obviously! Resources for frontend web developers are available in abundance on the internet. If you are just starting, you can start with html and CSS and then catch-up with Html5 and CSS3. However, these things are just for look and feel. If you want to add behavior, you will have to learn JavaScript as well. <br/><br/> Most importantly, you need to build what you learn. Keep up with the practice and you will do good. If you’re not getting any real time projects what you can do is make demo projects or prototypes and put them at places like GitHub or share them on LinkedIn with your connections add these to demonstrate your skills on freelancers where if your bid is correct, you’ll get the project. 
        <br/><br/>Below are the few things that can help you to stay firm to the decisions taken by yours 
        <ul className="parent-ul">
            <li>Decide between the framework. Pick only one but try all. The popular ones are: </li>
            <ul>
                <li>React.js</li>
                <li>Angular.js</li>
                <li>Vue.js</li>
            </ul>
        </ul>
        </span>,

        paraTwoTitle: "Pick a niche/domain",
        paraTwoContent: 
        <p>This might sound like we’re getting into digital marketing but believe me we’re still on the track. 
        Competition is good and that’s where growth happens but that also where failure takes place. Enter the competition but then pick your niche and be best in that niche and leave everything behind. Let’s understand this with an example: - 
        <br/><br/>
        Suppose if I ask you which is the best car brand on this planet? One will surely be confused because there’s a lot to pick but then do, we have a single answer to this question? We don’t and it’s just too hard to answer it, but what if I ask you what is the best car brand in terms of luxury? The answer to this becomes very easy, one without an argument can say it’s Rolls-Royce. Then if I ask you which is the best car brand on this planet in terms of performance? Again, the answer becomes simple (I don’t think I’m the right person to answer this question). 
        <br/><br/>
        In front-end development, pick what you want to do specifically with which platform you would like to go and which market are you targeting, like e-commerce or educational or only for blogs or anything. This will help you to eliminate a lot of unnecessary competition and will bring you peace if you have the clarity to go ahead.
        </p>,

        paraThreeTitle: "Structured Learning Course",
        paraThreeContent: 
        <p>
            What languages and technologies are used in this field? Make a list of the minimum you need to learn to achieve your goal from the previous step. The emphasis is on the minimum here, simply because you shouldn’t wait to learn everything in your chosen field before taking the first step towards your goal. You’ll be waiting a really long time            <br/><br/>
            What tools do you need? Find out the tools you need to learn. A code editor? Some software? Go ahead and install them but learn a little about them if you can try it at someplace do go ahead with it.            <br/><br/>
            What’s the learning order? Most fields require that you learn one tool/language first before another. Remember to always start with the fundamentals and move on from there but never forget to do the revision.
        </p>,

        paraFourTitle: "Construct Something",
        paraFourContent: 
        <p>
            As a beginner in development, it’s easy to get stuck in the tutorials and revision. A lot of people get stuck there, and stay beginners for way too long. This is where you watch tutorial after tutorial without ever getting your hands dirty. 
            Add a concept here to your dictionary which is “learn while doing”. Building projects is not mutually exclusive to the learning phase. You have to constantly iterate between learning and building because learning never actually ends. This is why it is emphasized for choosing learning resources that feature a lot of code-along projects to get you started practicing. Make sure to code along and not just watch. This helps you get started making something. 
            One way to get some practice is, at the end of every module, think of features that you can add to the project that you’ve coded. Just try to build something with the new knowledge that you have gained. It doesn’t have to be too fancy. The most important thing is that you write code, run into errors, google how to debug it, and gain more understanding. Practice solidifies knowledge, 
            One completed project is better than 100 watched tutorials.You can use google to find project ideas, or check out these fun ones. For project ideas, check out these fun ones or sign up on DevProjects. DevProjects is a free community where people can learn programming by working on curated projects from different stacks. Each project is designed by senior mentors and developers to help bridge the learning gap between theory and real-world code.

        </p>,

        paraFiveTitle: "Do some work on Networking",
        paraFiveContent: 
        <p>To network simply means to learn in public and put yourself out there. You don’t stand to gain much if you’re a silo. So, how do you put yourself out there? 

        You can join a community and take part in challenges like hackathons that inspire you at the same time with competition and also with scope. You become aware of people who are in the same field as you are, with the same struggles you have. You’ll also get the opportunity to collaborate and form lifelong friendships. 
        
        Opportunity no longer comes to the most qualified — it goes to the most visibly qualified. 
        Set up a GitHub account and push code there. Start a twitter account and talk about what you’re learning or building. LinkedIn is also very important.
        
        Asking and engaging with questions is another way to network and introduce yourself to other people. There is really no shame in asking questions. 
        
        If you get stuck on a particular problem, use social media and your physical connections to your advantage. Learn to slide into people’s DMs and emails. The only reason you may not get a reply is if you don’t know how to word your request well.
        </p>,

        paraSixTitle: "Summary",
        paraSixContent: 
        <p>
            One last thing: coding is difficult, and learning on your own can be even tougher. You’ll definitely come across some bad days.
            Just remember to go easy on yourself on days when things don’t make any sense. In the long run, your consistency and perseverance towards learning will definitely pay off. 
            Now go get your hoodie back on and start working.

        </p>,
    },

    {
        id: 4,
        name: 'takeovertheworld',
        caption: "By Rebecca Waterfield",
        titleL1: "Just 3 Seconds",
        titleL2: 'To Take Over The World',

        bannerImg: digitalmarketingBanner,

        titleMain: '3 Seconds To Take Over The World',
        keywords: "digital marketing, branding, social media marketing, Instagram, Facebook, twitter, strategy, network ",
        description: "We spend a lot of personal time doing scrolling down and our professional time hoping our content will make potential customers stop doing it.",

        paraOneTitle: 'Overview',
        paraOneContent: <p>
            Scrolling…we spend a hell of a lot of personal time doing it and a hell of a lot of our professional time hoping our content will make potential customers stop doing it!
            <br/><br/>
            Greek American engineer and physician Peter Diamandis once famously said that if you’re holding a smartphone in your hand today, you have more access to information than the president of the United States did in the late 1990s.
            <br/><br/>
            Whether it’s TikToks, tweets, ads, memes, stories, Snapchat, news – we’re constantly bombarded with snippets of content (some experts estimate we see anything between 4,000 and 10,000 messages a day) so it’s no wonder we have developed an in-built screening process that allows us to filter and scroll at lightning speed.
            <br/><br/>
            That’s great for us personally, it means our brains aren’t going into overdrive trying to collect and collate all the information that’s thrown our way. As marketers though, it’s not so great because it means we have to really do something jaw-dropping to make the scrolling stop
        </p>,

        paraTwoTitle: 'Impact starts with Intent',
        paraTwoContent: 
        <p>
            The simple truth is that you have no longer than 3 seconds to grab someone’s attention…to really stand out among the crowd and make an impact.
            <br/><br/>
            What that impact is could be anything from making them laugh, to making them hit the read more button to clicking through to your site and becoming part of your nurturing process.
            <br/><br/>
            That’s why the first thing you need to consider when you’re creating content of any kind is to consider what your intent is for this piece.
            <br/><br/>
            Your intent will determine the language you use, the tone you take, the imagery you add, the music that plays, and the platform you deliver it on. And, whichever platform you choose, you have to put your ego aside and understand that you’re just one of many fighting for clicks, laughs, and engagement.
            <br/><br/>
            It’s not a case of generating a whole stack of content and chucking it about hoping that something will stick, you need to curate GREAT content in order to achieve awareness. And awareness is the first step to generating more clicks, saves, and paying customers.
        </p>,

        paraThreeTitle: 'Build It and They Will Come – Or Not!',
        paraThreeContent: 
        <p>
            A thoroughly overused saying and one that really gets my goat because how will people know what you’ve built and where to find it if you don’t shout about it!
            This is where awareness comes in, and the importance of building a consistent brand that can increase revenue by up to 23%.
            <br/><br/>
            If you’re starting out on a brand awareness campaign then make sure you do a lot of the basics before you even begin generating content. Map out exactly what you aim to achieve, who you want to target and how you’ll reach them. It’s also handy to consider what you deem successful…do you want more likes or followers? Do you want more people to click through to your website? Do you want more people to sign up for your mailing list?
            <br/><br/>
            Once you’ve worked this all out then it’s time to put the pedal to the metal and crack on with some clickable content.
        </p>,

        paraFourTitle: 'The 3 Second Rule',
        paraFourContent: <p>
            From awareness to attention, and this takes us back to those magical 3 seconds!
            <br/><br/>
            Given how much information we digest on a daily basis you really need to work hard to create something that wows and makes people stop in their tracks.
            <br/><br/>
            It’s not (like we’ve been led to believe) that consumers of content have attention spans shorter than that of a goldfish, we’re just being pickier about who and what we give our attention to.
            <br/><br/>
            So how do you go about it? Well, a quirky take on a news story (just look at the content that was generated off the back of Mark Zuckerberg’s ‘Meta’ unveiling), an unpopular or startling opinion, a funny hack or a hilarious TikTok – it’s more about appealing to your consumers’ emotions than their logical brain.
            <br/><br/>
            However you choose to go about it, the first 3 seconds of your content needs to hook someone in, tell them what’s to come and why it matters to them. These critical seconds can win you a legion of new fans or condemn you to live in the internet wilderness, just hoping for someone to pass by and stop in a game of chance.

        </p>,

        paraFiveTitle: 'Handy Hacks to Hook Them In',
        paraFiveContent: <p>So now you’re chomping at the bit to get cracking and hook those people in but just in case you’re still sitting staring at a blank notebook waiting for inspiration to strike… Here are a few tips to help you out!
        
        </p>,

        paraSixTitle: 'The Hack List',
        paraSixContent: 
        <p>
            <strong>Picture This:</strong>
            In a world where you can be anything, be instagrammable! Sorry to the copywriters among us (I feel your pain) but visually appealing posts are more clickable. Spend time (and money if you can) in creating unique, eye-catching and engaging images that showcase what you’re all about. Don’t use the same stock images as everyone else, work out what you can do differently.
            <br/><br/>
            <storng>Headlines That Hit Hard</storng>
            Write short, catchy headlines that make people want to read on BUT avoid clickbait type headlines as they will only cause readers to lose faith in you and your content
            <br/><br/>
            <strong>It’s Business BUt It’s Personal</strong>
            By taking the time to segment your audience you can personalize the content you send out – not just in terms of offers but the language that you use when hitting up different audiences and the time that you send your content out depending on where they are and when they will be more likely to engage with you. If you’re a wedding planner you’re less likely to get engagement posting an offer at 10 am on a Monday morning when your dream client is at work than you are at 8 on a Saturday night when they’re sat down with a glass of wine and are scrolling through Pinterest for inspiration.
            <br/><br/>
            <strong>Pop Quiz Hot Shot</strong>
            Going all interactive is totally on point right now. Whether you’re a lover of Linkedin or an Insta Addict, you’ll see hundreds of polls, quizzes, Q&A stories, etc every day. You’ll probably know what your strippers’ name is, what you’d look like as a cat, or whether you’ll be on Santa’s naughty or nice list this Christmas…we just can’t help ourselves! If you get hooked into these interactive posts then so will your followers so get on it!
            <br/><br/>
            <strong>Get On Trend</strong>
            Researching what’s trending can take time, the time you just don’t have so hop on the Google Trends bandwagon and find out what people are talking about and hijack the conversation with your own content. Use imagery, headlines, interactive polls, etc to cut through the custard, and away you go! Get their attention now and begin nurturing an ongoing relationship that keeps people coming back for more, time and time again!

        </p>,
        
    }

],

}