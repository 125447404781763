import React from 'react'
import './HowWeWork.scss'
import { IoLogoJavascript } from 'react-icons/io'
import { IoLogoWordpress } from 'react-icons/io'
import { IoLogoReact } from 'react-icons/io5'
import { IoLogoHtml5 } from 'react-icons/io5'
import { IoLogoCss3 } from 'react-icons/io5'
import { IoLogoNodejs } from 'react-icons/io5'
import { IoLogoPython } from 'react-icons/io5'
import {FaBootstrap} from 'react-icons/fa'
import {FaAws} from 'react-icons/fa'
import {DiMongodb} from 'react-icons/di'
import {DiPhotoshop} from 'react-icons/di'
import {DiAndroid} from 'react-icons/di'
import {IoLogoFigma} from 'react-icons/io5'
import {DiPhp} from 'react-icons/di'
import {SiNativescript} from 'react-icons/si'
import {DiMysql} from 'react-icons/di'
import PinkBanner from './PinkBanner'


function HowWeWork() {
    return (
        <div id="hww">
            <div className="container">
                <div className="hww-wrapper">
                    <div className="hww-title">
                        <div className="line">
                            <h2>Tools We use</h2>
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className="col-6 col-md-3">
                            <div className="icon icon-1">
                                <IoLogoJavascript/>
                            </div>
                            <div className="icon icon-2">
                                <IoLogoWordpress/>
                            </div>
                            <div className="icon icon-2">
                                <IoLogoReact/>
                            </div>
                            <div className="icon icon-2">
                                <IoLogoHtml5/>
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="icon icon-2">
                                <IoLogoCss3/>
                            </div>
                            <div className="icon icon-2">
                                <FaBootstrap/>
                            </div>
                            <div className="icon icon-2">
                                <DiMongodb/>
                            </div>
                            <div className="icon icon-2">
                                <IoLogoNodejs/>
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="icon icon-2">
                                <IoLogoPython/>
                            </div>
                            <div className="icon icon-2">
                                <DiPhp/>
                            </div>
                            <div className="icon icon-2">
                                <DiPhotoshop/>
                            </div>
                            <div className="icon icon-2">
                                <IoLogoFigma/>
                            </div>
                        </div>

                        <div className="col-6 col-md-3">
                            <div className="icon icon-2">
                                <SiNativescript/>
                            </div>
                            <div className="icon icon-2">
                                <DiAndroid/>
                            </div>
                            <div className="icon icon-2">
                                <DiMysql/>
                            </div>
                            <div className="icon icon-2">
                                <FaAws/>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <PinkBanner/>
        </div>
    )
}

export default HowWeWork
