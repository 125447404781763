import React from 'react'
import './InnerHero.scss'

function InnerHero(props) {
    return (
        <div id="innerHero">
            
            <div className="ih-services">
                <div className="container">
                
                    <div className="ih-services__wrapper">
                        <div className="ih-services__title">
                            <div className="line">
                                <h2>Elevate Your Brand</h2>
                            </div>
                            <div className="line">
                                <h2>Elevate Yourself</h2>
                            </div>
                        </div>

                        <div className="ih-services-list">
                            <div className="ih-list__wrapper">
                                <ul>
                                    <li>Website Development</li>
                                    <li>Mobile Applications</li>
                                    <li>Website Design</li>
                                    <li>Branding</li>
                                    <li>Network Security</li>
                                    <li>Content Writing</li>
                                </ul>

                                <ul>
                                    <li>ERP Development</li>
                                    <li>CMS Development</li>
                                    <li>E-Commerce</li>
                                    <li>Hosting</li>
                                    <li>IOS</li>
                                    <li>Android</li>
                                </ul>
                            </div>
                        </div>  
                    </div>  
                </div>
            </div>

            <div className="ih-main">
                <div className="container">
                    <div className="ih-main__wrapper">
                        <div className="ih-para-one">
                            
                            <p>At Logion, we design mobile apps and digital products that focus on enhancing usability, accessibility and delight for your users. We work from the very start of the product development cycle and create the complete UX/UI design. We can alternatively work to create a comprehensive UI along with the complete design system. </p>
                        </div>

                        <div className="ih-para-one">
                            
                            <p>We are a team of creative engineers, and all our UX/UI work is rooted in user research, best practices and a lot of common sense. </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default InnerHero
