import React,{useEffect} from 'react'
import InnerHero from '../Components/InnerHero'
import Navbar from '../Components/Navbar'
import Process from '../Components/Process'
import gsap from 'gsap';
import CSSRulePlugin from "gsap/CSSRulePlugin";
import HowWeWork from '../Components/HowWeWork';
import Footer from '../Components/Footer'
import { ServicesData } from '../Components/Data';
import ScrollTrigger from 'gsap/ScrollTrigger';
import PageHead from '../Components/PageHead';
import Banner from '../Components/Banner';
import {Helmet} from "react-helmet";


function Services() {

    useEffect(()=>{

      gsap.fromTo('#services', {
        opacity: 0
      }, {
        opacity: 1
      }, 0.3)

    // Page Header animation start

    gsap.registerPlugin(CSSRulePlugin);

      gsap.timeline().from('#page-head .line h2', {
          y: '120px',
          skewY: 7,
          stagger: {
              amount: 0.3
          }
      }).from("#page-head .innerHero__caption", {
        opacity: 0
      }, 0.5).to('.ih-banner__wrapper', {
        // cssRule:{
        //   width: '100%'
        // },
        opacity: 1
      });

    // Page Header animation end

    // Process svg animation

    gsap.to("#process > .plus3",{
      y: '100px',
      scrollTrigger:{
        trigger: '#process',
        scroller: '#root',
        start: 'top',
        end: 'top 50%',
        scrub: 1.5,
      }
    })

    // Inner Hero Animation Start

      gsap.timeline({
        scrollTrigger: {
          trigger: '.ih-services',
          scroller: "#root",
          start: 'top 60%',
          end: 'top 60%',
        }
      }).from('.ih-services__title .line h2',{
             y: '120px',
            ease: "power4.out",
            skewY: 7,
            stagger: {
                amount: 0.3
            }
      }).from('.ih-list__wrapper ul li', {
        opacity: 0,
        stagger: {
          amount: 0.3
        }
      }).from('.ih-main__wrapper > div', {
        opacity: '0',
        y: '30px',
        stagger: 0.2
      }, 1)
      
    // Inner Hero Animation End

    // Process Animation Start

      gsap.timeline({
        scrollTrigger: {
          trigger: '.process-inner',
          scroller: "#root",
          start: 'top 60%',
          end: 'top 60%',
          scrub: 1
        }
      }).fromTo('.process-inner .line h2', {
          y: '120px',
          ease: "power4.out",
          opacity: 0,
          skewY: 7,
          stagger: {
              amount: 0.3
          }
      }, {
        y: '0px',
        opacity: 1,
        skewY: 0,
        stagger: {
          amount: 0.3
      }
      });
      
    // Process Animation End


    // The HWW Section Start

        gsap.timeline({
          scrollTrigger: {
            trigger: '#hww',
            scroller: "#root",
            start: 'top 200px',
            end: 'top',
          }
        }).from('.hww-title .line h2',{
              y: '120px',
              ease: "power4.out",
              skewY: 7,
              stagger: {
                  amount: 0.3
              }
        }).from(".icon", {
          opacity: 0,
          stagger: {
            amount: 0.5
          }
        })      

          //The banner

          gsap.timeline({
            scrollTrigger:{
              trigger: '.ih-banner',
              scroller: "#root",
              start: 'top 100%',
              end: 'top -50%',
            }
          }).to('.overlay-img', {
              left: '100%',
          }).to('.ih-banner__wrapper > img', {
            scale: 1.05
          }, "<0.5")

          // The header color change

          
      gsap.timeline({
        scrollTrigger:{
          trigger: '#process',
          scroller: '#root',
          start: 'top',
          end: 'top',
          scrub: 1
        }
      }).to('.nav-toggler span', {
        backgroundColor: "#fff"
      }, 0)

      gsap.timeline({
        scrollTrigger:{
          trigger: '#hww',
          scroller: '#root',
          start: 'top',
          end: 'top',
          scrub: 1
        }
      }).to('.nav-toggler span', {
        backgroundColor: "#000"
      }, 0)

      gsap.timeline({
        scrollTrigger:{
          trigger: '.hww-banner',
          scroller: '#root',
          start: 'top',
          end: 'top',
          scrub: 1
        }
      }).to('.nav-toggler span', {
        backgroundColor: "#fff"
      }, 0)

      gsap.timeline({
        scrollTrigger:{
          trigger: '#footer',
          start: 'top',
          end: 'top',
          scroller: '#root',
          scrub: 1
        }
      }).to('.nav-toggler span', {
        backgroundColor: "#fff"
      }, 0)

          //The pinned Text

          ScrollTrigger.matchMedia({
            "(min-width: 1200px)": function(){
              const size = document.querySelector('.process-inner').getBoundingClientRect().height;

              ScrollTrigger.create({
                trigger: '.process-inner',
                scroller: '#root',
                start: "top top", 
                end: () => `+=${size}px`,
                scrub: true,
                pin: '.process-inner__title',
                
              })
            }
          })
    })
    return (
        <div id="services">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Logion Solutions is an award winning website development company that specializes in high end website development. Check out our services here"/>
                <meta property="og:title" content="Logion Solutions | Services | Digital Agency in Ahmedabad, Gujarat | Digital Design, UIUX, Responsive Web Design" />
                <meta property="og:description" content="Logion Solutions is an award winning website development company that specializes in high end website development. Check out our services here"/>
                <meta name="keywords" content="Logion, web and app development services, web and mobile app services, web services rest api, wordpress, developers ahmedabad, arbre, branding, marketing firms, social media marketing, hosting, website security, ecommerce, content writers ahmedabad, android app"/>
                <title>Services | Web Design | Website Development | Mobile Apps Development | UI & UX | e-commerce | e-commerce Development | Branding | ERP | Content Writing | Social Media Marketing  </title>
            </Helmet>

            <Navbar color="#000" logoColor="#000"/>
            <div className="container">
                <PageHead caption={ServicesData.caption} titleL1={ServicesData.titleL1} titleL2={ServicesData.titleL2}/>
            </div>
            <Banner imgUrl={ServicesData.bannerImage}/>
            <InnerHero servicesTitle = {ServicesData.servicesTitle}/>
            <Process/>
            <HowWeWork/>
            <Footer/>
        </div>
    )
}

export default Services
