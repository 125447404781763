import React, { Component } from 'react'
import Banner from './Banner';
import { BlogData } from './Data';
import Navbar from './Navbar';
import gsap from 'gsap';
import PageHead from './PageHead';
import './SingleBlog.scss'
import Footer from './Footer';
import {Helmet} from 'react-helmet'


export default class SingleProject extends Component {

    componentDidMount(){
        gsap.timeline().from('#page-head .line h2', {
            y: '120px',
            skewY: 7,
            stagger: {
                amount: 0.3
            }
        }).from("#page-head .innerHero__caption", {
            opacity: 0
        }, 0.5).to('.ih-banner__wrapper', {
            opacity: 1            
        })

    }


    render() {

        gsap.timeline().from('#page-head .line h2', {
            y: '120px',
            skewY: 7,
            stagger: {
                amount: 0.3
            }
        }).from("#page-head .innerHero__caption", {
            opacity: 0
        }, 0.5).to('.ih-banner__wrapper', {
            opacity: 1            
        })


        let getName = this.props.match.params.name;
        // let getData = ProjectData.mainData.name;
        // const getFinal;
        let getData = BlogData.mainData.find(function(e){
            if(e.name === getName){
                return e
            }

            else return false
        });
       
        return (
            <div id="single-blog">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content={getData.description}/>
                    <meta property="og:title" content={getData.titleMain}/>
                    <meta property="og:description" content={getData.description}/>
                    <meta name="keywords" content={getData.keywords}/>
                    <title>{getData.titleMain}</title>
                </Helmet>
                <Navbar color="#111" logoColor="#000"/>

                <div className="container">
                    <PageHead caption={getData.caption} titleL1={getData.titleL1} titleL2={getData.titleL2}/>
                </div>

                <Banner imgUrl={getData.bannerImg}/>
                <div className="container">                
                    <div className="blogs-wrapper">
                        <div className="blogs-inner">
                            <div className="row">
                                <div className="col-12 col-md-8">
                                    <div className="para-one-title">
                                        <h2>{getData.paraOneTitle}</h2>
                                    </div>

                                    <div className="para-one-content">
                                        {getData.paraOneContent}
                                        
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-8">
                                    <div className="para-one-title">
                                        <h2>{getData.paraTwoTitle}</h2>
                                    </div>

                                    <div className="para-one-content">
                                        {getData.paraTwoContent}
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-8">
                                    <div className="para-one-title">
                                        <h2>{getData.paraThreeTitle}</h2>
                                    </div>

                                    <div className="para-one-content">
                                       <span>{getData.paraThreeContent}</span>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-8">
                                    <div className="para-one-title">
                                        <h2>{getData.paraFourTitle}</h2>
                                    </div>

                                    <div className="para-one-content">
                                        <p>{getData.paraFourContent}
                                        </p>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-8">
                                    <div className="para-one-title">
                                        <h2>{getData.paraFiveTitle}</h2>
                                    </div>

                                    <div className="para-one-content">
                                        <p>{getData.paraFiveContent}
                                        </p>
                                       
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-8">
                                    <div className="para-one-title">
                                        <h2>{getData.paraSixTitle}</h2>
                                    </div>

                                    <div className="para-one-content">
                                        <p>{getData.paraSixContent}
                                        </p>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  

                <Footer/>
            </div>
        )
    }
}
