import React from 'react'
import './AboutInner.scss'

function AboutInner(props) {
    return (
        <div className="about-inner">
            <div className="about-inner__wrapper">
                <div className="about-inner__content">
                    <div className="ai-para ai-para-one">
                        <div className="ai-para-title">
                              <h4>{props.title}</h4>
                        </div>
                        <div className="ai-para-content">
                            <p>{props.content}</p>
                        </div>
                    </div>
                </div>
          </div>
            
        </div>
    )
}

export default AboutInner
