import React from 'react'
import './Banner.scss'

function Banner(props) {
    return (
        <div>
            <div className="ih-banner">
                <div className="ih-banner__wrapper">
                    {/* <div className="overlay-img"></div> */}
                    {/* <img src={trial} alt="" /> */}
                    <div className="img">
                        <img src={props.imgUrl} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner
