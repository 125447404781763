import './App.css';
import React from 'react';
import '../node_modules/bootstrap/scss/bootstrap.scss'
import { BrowserRouter, Route, Switch} from "react-router-dom";
import Home from './Pages/Home';
import SingleProject from './Components/SingleProject';
import SingleBlog from './Components/SingleBlog';
import Services from './Pages/Services';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import About from './Pages/About';
import ScrollToTop from './Components/ScrollToTop';
import Blogs from './Pages/Blogs';
import { Helmet } from 'react-helmet'


const routes = [
  {path: "/", name: "Home", Component: Home},
  {path: "/about", name: "About", Component: About},
  {path: "/projects", name: "Projects", Component: Projects},
  {path: "/services", name: "Services", Component: Services},
  {path: "/contact", name: "Contact", Component: Contact},
  {path: "/blogs", name: "Blogs", Component: Blogs},
  
  ];

const routes2 = [
  {path: '/blogs/:name', Component: SingleBlog },
  {path: '/projects/:name', Component: SingleProject },
  
]

function App() {

  return (
    <div className="App">
      <div id="scroller">
            <Helmet>
                <meta charSet="utf-8" />
                <title>My Title</title>
            </Helmet>
            <BrowserRouter basename="/Pages">
              <ScrollToTop/>
              <Switch>
              {routes.map(({path, Component }) => (
                  <Route key={path} path={path} exact> 
                    <Component/>
                  </Route>
                ))}
              </Switch>

                <Switch>
                {routes2.map(({path, Component }) => (
                  <Route key={path} path={path} exact render={props =>(
                    <Component {...props}/>
                  )}> 
                  </Route>
                ))}    
           
              </Switch>
              </BrowserRouter>
      </div>
      
    </div>
  );
}

export default App;


