import React from 'react'
import './Process.scss'
import {ReactComponent as Plus3} from '../images/Final PLus/3.svg'
import {ReactComponent as Circle} from '../images/Circle/Circle.svg'

function Process() {
    return (
        <div id="process">
            <div className="plus3">
                <Plus3/>
            </div>

            <div className="circle">
                <Circle/>
            </div>

            <div className="container">
                <div className="process-wrapper">
                    <div className="process-inner">
                        <div className="row">
                            <div className="col-12 col-xl-6 the-height">
                                <div className="process-inner__title">
                                     <div className="line">
                                         <h2>Design-Driven</h2>
                                     </div>

                                     <div className="line">
                                         <h2>Strategy-led</h2>
                                     </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-8 col-xl-6">
                           
                                <div className="process-cards">
                                    <p className="process-caption">The Process</p>
                                    <div className="process-card">
                                        <div className="process-card-title">
                                            <h3>Research</h3>
                                        </div>
                                        <div className="pocess-card-content">
                                            <p>We believe in a thorough, yet efficient, research phase in order to make sure we and our client partners have all we need to make informed product decisions.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="process-card">
                                        <div className="process-card-title">
                                            <h3>Strategize</h3>
                                        </div>
                                        <div className="pocess-card-content">
                                            <p>We craft research-backed product strategies that help our process move through the key execution stages in an efficient manner, without the need to backtrack</p>
                                        </div>
                                    </div>

                                    <div className="process-card">
                                        <div className="process-card-title">
                                            <h3>Design</h3>
                                        </div>
                                        <div className="pocess-card-content">
                                            <p>We bring together all learnings, feature lists and user flows into our UX Design process to create extensive, robust Wireframes, making sure all user experience nuances are taken into account. We then bring Brand Design assets into the product design process to produce the entire set of UI Design for all screens, states and visual experiences.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="process-card">
                                        <div className="process-card-title">
                                            <h3>Develop</h3>
                                        </div>
                                        <div className="pocess-card-content">
                                            <p>Our team is fanatical about coding “pixel perfect” user interfaces, matching our final and approved designs as closely as possible. We craft advanced websites & mobile applications, making sure performance is the priority in all environments.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="process-card">
                                        <div className="process-card-title">
                                            <h3>Execute and Iterate</h3>
                                        </div>
                                        <div className="pocess-card-content">
                                            <p>Post development we move on to the testing phase of the service, where we check all aspects and functionalities of the product and execute the necessary changes to make the product stand out against its competitors in the market. 
                                            </p>
                                        </div>
                                    </div>

                                    <div className="process-card">
                                        <div className="process-card-title">
                                            <h3>Launch</h3>
                                        </div>
                                        <div className="pocess-card-content">
                                            <p>Building a product is only the first step. Ongoing management and maintenance of the digital products we create is where the real challenge, and fun, begins. This is where we strive to provide efficient after sales and maintenance services</p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Process
