import React from 'react'
import './HomeBlogs.scss'
import { BlogsFront } from './Data'
import { Link } from 'react-router-dom'

function HomeBlogs() {
    return (
        <div id="HomeBlogs">
            <div className="container">
                <div className="HB-wrapper">
                    <div className="HB-title">
                        <div className="line">
                            <h1>Insights</h1>
                        </div>
                        <p>Check out our blogs</p>
                    </div>

                    <div className="HB-main">
                        <div className="row">

                            <div className="col-12 col-md-6">
                                <Link to={BlogsFront.link1}>
                                <div className="HB-main__inner">
                                        <div className="HB-inner__img">
                                            <img src={BlogsFront.image1} alt="" />
                                        </div>
                                
                                    <div className="HB-main__content">
                                        <h2>{BlogsFront.title1}</h2>
                                        <p>{BlogsFront.caption1}</p>
                                    </div>
                                </div>
                                </Link>
                            </div>

                            <div className="col-12 col-md-6 right-blog">
                                <Link to={BlogsFront.link2}>
                                <div className="HB-main__inner">
                                    <div className="HB-inner__img">
                                        <img src={BlogsFront.image3} alt="" />
                                        
                                    </div>

                                    <div className="HB-main__content">
                                        <h2>{BlogsFront.title2}</h2>
                                        <p>{BlogsFront.caption2}</p>
                                    </div>
                                </div>
                                </Link>
                            </div>

                        </div>

                        <div className="row">

                            <div className="col-12 col-md-6">
                                <Link to={BlogsFront.link3}>
                                <div className="HB-main__inner">
                                    <div className="HB-inner__img">
                                        <img src={BlogsFront.image2} alt="" />
                                    </div>

                                    <div className="HB-main__content">
                                        <h2>{BlogsFront.title3}</h2>
                                        <p>{BlogsFront.caption3}</p>
                                    </div>
                                </div>
                                </Link>
                            </div>

                            <div className="col-12 col-md-6 right-blog">
                                <Link to={BlogsFront.link4}>
                                <div className="HB-main__inner">
                                    <div className="HB-inner__img">
                                        <img src={BlogsFront.image4} alt="" />
                                        
                                    </div>

                                    <div className="HB-main__content">
                                        <h2>{BlogsFront.title4}</h2>
                                        <p>{BlogsFront.caption4}</p>
                                    </div>
                                </div>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBlogs
