import React, { useRef } from 'react'
import './ContactForm.scss'
import emailjs from 'emailjs-com'

function ContactForm() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_stxzv8k', 'template_cjmpko8', form.current, 'user_8B4esu6AJUOFz8d1Zd382')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

          e.target.reset();
          alert("The message has been sent. We will get back to you")
      };

    return (
        <div id="form">
            <div className="form-wrapper">
                <form ref={form} onSubmit={sendEmail}>
                    <div className="row form-row-custom">
                        <div className="col-6">
                            <input type="text" placeholder="Your Name" name="fname"/>
                        </div>
                        <div className="col-6">
                            <input type="text" placeholder="Last Name" name="lname"/>
                        </div>
                    </div>
                    
                    <div className="row form-row-custom">
                        <div className="col-4">
                            <input type="text" placeholder="Company Name" name="cname"/>
                        </div>
                        <div className="col-4">
                            <input type="tel" placeholder="Phone Number" name="tel"/>
                        </div>
                        <div className="col-4">
                            <input type="email" placeholder="Email" name="email"/>
                        </div>
                    </div>

                    <div className="row form-row-custom">
                        <div className="col-12">
                            <textarea name="" id="" cols="30" rows="10" placeholder="Describe Your Project" name="description"></textarea>
                        </div>
                    </div>

                    <div className="row form-custom-row">
                        <button className="submit-btn" type="submit">Submit</button>
                    </div>
                </form>
            </div>           
        </div>
    )
}
export default ContactForm
