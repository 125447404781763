import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Scrollbar from 'smooth-scrollbar';
import ScrollTrigger from 'gsap/ScrollTrigger';
import gsap from 'gsap/all';

export default function ScrollToTop() {

  const options = {
    damping: 0.1, 
    delegateTo: document 
  }
  const { pathname } = useLocation();
  
  useEffect(()=>{
  
    gsap.registerPlugin(ScrollTrigger);

    const bodyScrollBar = Scrollbar.init(document.querySelector('#root'), options);

    ScrollTrigger.scrollerProxy('#root', {
      scrollTop(value) {
        if (arguments.length) {
          bodyScrollBar.scrollTop = value;
        }
        return bodyScrollBar.scrollTop;
      },
      
    });
  
    bodyScrollBar.addListener(ScrollTrigger.update);
    // const fixedElem = document.getElementsByClassName('frame')[0]
    const fixedElem2 = document.getElementsByClassName('nav-toggler')[0]
    const fixedElem3 = document.getElementById('nav-overlay');

  
      bodyScrollBar.addListener( status => {
        const offset = status.offset
        if (window.innerWidth > 1200){
            // fixedElem.style.top = offset.y + 'px'
            fixedElem2.style.top = offset.y + 'px'
            fixedElem3.style.top = offset.y + 'px'
          }

          else {
            fixedElem3.style.top = offset.y + 'px'
          }
        })
        
      bodyScrollBar.scrollTo(0,0);
  
  }, [pathname])

  return null;
}
