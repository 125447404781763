import React, {useEffect} from 'react'
import AboutInner from '../Components/AboutInner'
import Banner from '../Components/Banner'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import PageHead from '../Components/PageHead'
import PinkBanner from '../Components/PinkBanner'
import CSSRulePlugin from "gsap/CSSRulePlugin";
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import '../Components/About.scss'
import { AboutData } from '../Components/Data'
import { withRouter } from 'react-router'
import {Helmet} from "react-helmet";


function About(props) {

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(CSSRulePlugin);


    useEffect(()=>{

        gsap.fromTo('#about', {
            opacity: 0
          }, {
            opacity: 1
          }, 0.3)
        // Banner and Hero animation start

        gsap.timeline().from('#page-head .line h2', {
            y: '120px',
            skewY: 7,
            stagger: {
                amount: 0.3
            }
        }).from("#page-head .innerHero__caption", {
            opacity: 0
        }, 0.5).to('.ih-banner__wrapper', {
            opacity: 1            
        })

        // Banner and Hero Animation end
        
        //Navigation Animation

        gsap.timeline({
            scrollTrigger: {
                trigger: '.hww-banner',
                scroller: "#root",
                start: 'top center',
                end: 'top 50%',
                scrub: true,
              
            }
          }).to('.nav-toggler__span', {
              backgroundColor: '#fff',
              duration: 0.02
          })

          gsap.timeline({
            scrollTrigger:{
              trigger: '.hww-banner',
              scroller: '#root',
              start: 'top',
              end: 'top',
              scrub: true
            }
          }).to('.logo svg path',{
            fill: '#fff'
          }, 0).to('.header-links a p', {
            color: "#fff"
          }, 0).to('.nav-toggler span', {
            backgroundColor: "#fff"
          }, 0)

          gsap.timeline({
            scrollTrigger: {
                trigger: '#footer',
                scroller: "#root",
                start: 'top top',
                end: 'top top',
                scrub: true,
                
            }
          }).to('.header-links a p', {
              color: '#fff',
              duration: 0.02
          })

          gsap.timeline({
            scrollTrigger:{
              trigger: '#footer',
              start: 'top',
              end: 'top',
              scroller: '#root',
              scrub: 1
            }
          }).to('.logo svg path',{
            fill: '#fff'
          }, 0).to('.header-links a p', {
            color: "#fff"
          }, 0).to('.nav-toggler span', {
            backgroundColor: "#fff"
          }, 0)

            
    })
    
    return (
        <div id="about" >

            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Passionate about making wonders happen on screen. Developing Websites and Applications for client’s as if it’s our own."/>
                <meta property="og:title" content="Logion Solutions | About us | Digital Agency in Ahmedabad, Gujarat | Digital Design, UIUX, Responsive Web Design" />
                <meta property="og:description" content="Logion Solutions is an award winning website development company that specializes in high end website development. Check out our services here"/>
                <meta name="keywords" content="Logion, web and app development services, web and mobile app services, web services rest api, wordpress, developers ahmedabad, arbre, branding, marketing firms, social media marketing, about us, story, journey, information technology"/>
                <title>About us | Our Journey | Our Team | Freelancers to Company | UI & UX | e-commerce | e-commerce Development | Branding | ERP | Content Writing | Social Media Marketing  </title>
            </Helmet>

            <Navbar color="#000" logoColor="#000"/>
                <div className="container" >
                    <PageHead caption="who we are" titleL1="Absolute nerds" titleL2=" with Great Ideas"/>    
                </div>

            <Banner imgUrl={AboutData.bannerImage}/>
     
            <div className="container about-cont">
                <AboutInner title={AboutData.title1} content={AboutData.content1}/>
                <AboutInner title={AboutData.title2} content={AboutData.content2}/>
                <AboutInner title={AboutData.title3} content={AboutData.content3}/>
                <AboutInner title={AboutData.title4} content={AboutData.content4}/>
                <AboutInner title={AboutData.title5} content={AboutData.content5}/>
            </div>
             
             <PinkBanner/>
             <Footer/>
        </div>
    )
}

export default withRouter(About);
