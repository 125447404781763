import React from 'react'
import './PageHead.scss'
import {ReactComponent as Plus3} from '../images/Final PLus/3.svg'
import {ReactComponent as Circle} from '../images/Circle/Circle.svg'

function PageHead(props) {

        return (
        <div id="page-head">

            <div className="plus3">
                <Plus3/>
            </div>

            <div className="circle">
                <Circle/>
            </div>

            <div className="innerHero__wrapper">
                    <div className="">
                        <p className="innerHero__caption">
                            {props.caption}
                        </p>
                        <div className="innerHero__title">
                            <div className="line">
                                <h2>{props.titleL1}</h2>
                            </div>
                            
                            <div className="line">
                                <h2>{props.titleL2}</h2>
                            </div>
                        </div>
                    </div>

                </div>
        </div>
    )
}

export default PageHead
