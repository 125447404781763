import React from 'react'
import './Footer.scss'
import {ReactComponent as Logo} from '../images/Final Logion/Final.svg';
import { Link } from 'react-router-dom';
import { FiInstagram, FiFacebook } from 'react-icons/fi'
import { FaPinterestP } from 'react-icons/fa'


function Footer() {
    return (
        <div id="footer">
            <div className="container">
                <div className="footer-wrapper">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <div className="footer-company">
                                <div className="footer-logo">
                                    <Logo/>
                                </div>

                                <div className="footer-caption">
                                    <p>Let's make IT happen</p>
                                </div>

                                <div className="footer-social">
                                    <a href="https://www.instagram.com/logionsolutions/?hl=en"><span><FiInstagram/> </span></a> <a href="https://www.facebook.com/Logion-Solutions-104601462052866"><span><FiFacebook/></span></a> <a href="https://pin.it/4O8d2rQ"> <span className="pint"><FaPinterestP/></span></a> 
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 offset-md-1">
                            <div className="footer-meet">
                                <div className="get-in-touch">
                                    <div className="git-title">
                                        <p>Get in Touch</p>
                                    </div>

                                    <div className="git-content">
                                        <p>info@logionsolutions.com</p>
                                        <p>+91-7358103456</p>
                                        
                                    </div>
                                </div>
                                <div className="explore">
                                    <div className="explore-title">
                                        <p>Explore</p>
                                    </div>

                                    <div className="explore-links">
                                        <ul>
                                            <Link to="/"><li>Home</li></Link>
                                            <Link to="/projects"><li>Work</li></Link>
                                            <Link to="/services"><li>Services</li></Link>
                                            <Link to="/blogs"><li>Blogs</li></Link>
                                        </ul>
                                    </div>

                                    <div className="explore-content">
                                        <p>107, Sahajanand Complex, Helmet Cross Roads, Ahmedabad, Gujarat, India</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
