import React from 'react'
import './OurWork.scss'
import { Link } from 'react-router-dom'

function OurWork(props) {
    return (
        <div id="our-work">
            <div className="homework-inner__work">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="homework-inner__img">   
                            <div className="hw-wrapper">
                                <Link to={props.link1}>
                                    <div className="hw-wrapper__img">
                                        <img src={props.image} alt="" /> 
                                    </div>

                                    <div className="proj-title">
                                        <h3>{props.workTitle}</h3>   
                                        <p>{props.workCaption}</p>
                                    </div>
                                </Link>
                            </div>              
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <div className="homework-inner__img right"> 
                            <Link to={props.link2}>
                                <div className="hw-wrapper">
                                    <div className="hw-wrapper__img">
                                        <img src={props.image2} alt="" /> 
                                    </div>

                                    <div className="proj-title">
                                        <h3>{props.workTitle2}</h3>   
                                        <p>{props.workCaption2}</p>
                                    </div>
                                </div>  
                            </Link>                 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurWork
