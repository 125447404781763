import React from 'react'
import './ButtonWhite.scss'

function Button() {
    return (

        <div className="button-white">
            <div className="btn-main">
                <div className="btn-inner">
                    Know More
                </div>
                <div className="right-dash"></div>
            </div>
        </div> 
    )
}

export default Button
