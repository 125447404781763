import React, {useEffect} from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import OurWork from '../Components/OurWork'
import PageHead from '../Components/PageHead'
import gsap , {Power3} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import '../Components/Projects.scss'
import {ProjectsData} from '../Components/Data.js'
import {Helmet} from "react-helmet";


function Projects() {

    gsap.registerPlugin(ScrollTrigger);

    gsap.timeline({
      scrollTrigger:{
        trigger: '#footer',
        start: 'top',
        end: 'top',
        scroller: '#root',
        scrub: 1
      }
    }).to('.logo svg path',{
      fill: '#fff'
    }, 0).to('.header-links a p', {
      color: "#fff"
    }, 0).to('.nav-toggler span', {
      backgroundColor: "#fff"
    }, 0)

    useEffect(()=>{
      gsap.fromTo('#projects', {
        opacity: 0
      }, {
        opacity: 1
      }, 0.3)

      gsap.timeline().from('#page-head .line h2', {
        y: '120px',
        skewY: 7,
        stagger: {
            amount: 0.3
        }
        }).from("#page-head .innerHero__caption", {
          opacity: 0
      }, 0.5)
       //Navigation animation

        gsap.timeline({
          scrollTrigger: {
              trigger: '#footer',
              scroller: "#root",
              start: 'top top',
              end: 'top top',
              scrub: true,
              
          }
        }).to('.nav-toggler__span', {
            backgroundColor: '#fff',
            duration: 0.02
        })

        //Fixed elements


        var tow = gsap.timeline({
          scrollTrigger: {
            trigger: '#our-work',
            scroller: "#root",
            start: 'top 80%',
            end: 'top 80%',
            
          }
        })

        tow.from('.hw-wrapper__img ', {
          opacity: 0,
          scale: 1.1,
          duration: 0.1,
          ease: Power3.easeOut
        }).to('.hw-wrapper__img > img', {
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          ease: Power3.easeOut
        }, '<0.2')



        //The animations

        ScrollTrigger.matchMedia({
          "(min-width: 1200px)": function(){
            const rights = gsap.utils.toArray('.right');

              rights.forEach(right=>{
                  
                  gsap.fromTo(right, {
                      y: '100px',
                      scrollTrigger: {
                        trigger: '#HomeWork',
                        scroller: "#root",
                        top: 'top 200px',
                        scrub: 1,
                        
                      }
                    }, {
                        y: '-100px',
                        scrollTrigger: {
                            trigger: '#HomeWork',
                            scroller: "#root",
                            top: 'top 200px',
                            scrub: 1,
                            
                          }
                    })
              })
          }
        })

        
    })

    return (
        <div id="projects">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="We work with several industries to provide website & application solutions. See our portfolio to get insights about our expertise & knowledge"/>
                <meta property="og:title" content="Logion Solutions | Work | Website & App Development Firm | Digital Design, UIUX, Responsive Web Design" />
                <meta property="og:description" content="We work with several industries to provide website & application solutions. See our portfolio to get insights about our expertise & knowledge"/>
                <meta name="keywords" content="Logion, Logion Solutions, featured projects, work, websites, applications, developers, design, our work, web knowledge, web expertise, sample projects, Ahmedabad, Arbre"/>
                <title>Work | Web Design | Website Development | Mobile Apps Development | UI & UX | e-commerce | e-commerce Development | Branding | ERP | Content Writing | Social Media Marketing  </title>
            </Helmet>
            <Navbar color="#000" logoColor="#000"/>
            
            <div className="container">
                <PageHead caption="Our Work" titleL1 = "Check Out" titleL2 = "The Best of Us"/>
                <div className="projects-wrapper">
                    <OurWork link1="/projects/plants" image={ProjectsData.image1} workTitle={ProjectsData.workTitle1} workCaption={ProjectsData.workCaption1} link2="/projects/kasper" image2={ProjectsData.image2} workTitle2={ProjectsData.workTitle2} workCaption2={ProjectsData.workCaption2}/>
                    <OurWork link1="/projects/oneDegree" image={ProjectsData.image3} workTitle={ProjectsData.workTitle3} workCaption={ProjectsData.workCaption3} link2="/projects/arbre" image2={ProjectsData.image4} workTitle2={ProjectsData.workTitle4} workCaption2={ProjectsData.workCaption4}/>
                    <OurWork link1="/projects/sugarEcomm" image={ProjectsData.image5} workTitle={ProjectsData.workTitle5} workCaption={ProjectsData.workCaption5} link2="/projects/marshalTraining" image2={ProjectsData.image6} workTitle2={ProjectsData.workTitle6} workCaption2={ProjectsData.workCaption6}/>
                    <OurWork link1="/projects/dela" image={ProjectsData.image7} workTitle={ProjectsData.workTitle7} workCaption={ProjectsData.workCaption7} link2="/projects/wajooba" image2={ProjectsData.image8} workTitle2={ProjectsData.workTitle8} workCaption2={ProjectsData.workCaption8}/>
                </div>
            </div>
            
            <Footer/>
        </div>
    )
}

export default Projects
