import React from 'react'
import './Button.scss'

function Button(props) {
    return (

            <div className="button-black">
                <div className="btn-main">
                    <div className="btn-inner">
                        {props.text}   
                    </div>
                    <div className="right-dash"></div>
                </div>
            </div>
            
        
    )
}

export default Button
