import React, {useEffect} from 'react'
import ContactForm from '../Components/ContactForm'
import Footer from '../Components/Footer'
import Navbar from '../Components/Navbar'
import PageHead from '../Components/PageHead'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {Helmet} from 'react-helmet'

function Contact() {

    gsap.registerPlugin(ScrollTrigger);

    useEffect(()=>{

        gsap.timeline({
            scrollTrigger:{
              trigger: '#footer',
              start: 'top',
              end: 'top',
              scroller: '#root',
              scrub: 1
            }
          }).to('.logo svg path',{
            fill: '#fff'
          }, 0).to('.header-links a p', {
            color: "#fff"
          }, 0).to('.nav-toggler span', {
            backgroundColor: "#fff"
          }, 0)

          
        gsap.fromTo('#contact', {
            opacity: 0
          }, {
            opacity: 1
          }, 0.3)

        gsap.timeline().from('#page-head .line h2', {
            y: '120px',
            skewY: 7,
            stagger: {
                amount: 0.3
            }
            }).from("#page-head .innerHero__caption", {
              opacity: 0
          }, 0.5).from('form', {
              opacity: 0
          })
    })
    return (
        <div id="contact">
          <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Can’t wait to hear about your new business idea! Let’s create something unique to give your venture the right branding and website or application it needs."/>
                <meta property="og:title" content="Contact us | Want n exceptional Digital Presence? | Get In Touch Now" />
                <meta property="og:description" content="Can’t wait to hear about your new business idea! Let’s create something unique to give your venture the right branding and website or application it needs"/>
                <meta name="keywords" content="Logion, website, website design, web development, Ahmedabad, Arbre, application development, templates, branding, figma design, website developers in Ahmedabad, web developers near me, web developer app, ecommerce, cms development"/>
                <title>Contact us | Want A Digital Presence? | Get In Touch Today!</title>
            </Helmet>
            <Navbar color="#000" logoColor="#000"/>
            <div className="container">
                <PageHead titleL1="Tell us about" titleL2="your project" caption="Talk to us"/>
                <ContactForm/>
            </div>
            
            <Footer/>
        </div>
    )
}

export default Contact
