import React from 'react'
import './HomeWork.scss'
import './Data'
import OurWork from './OurWork'
import Button from './Button'
import { Link } from 'react-router-dom'
import {HomeWork_Home} from './Data'

function HomeWork() {

    return (
        <div id="HomeWork" className="dark-nav">
            <div className="container">
                <div className="homework-inner__wrapper">
                    <div className="homework-header">
                        <div className="line">
                            <h1>Featured Projects</h1>
                        </div>
                        
                        <p>Explore some of our best works</p>
                        {/* <div className="btn-main">
                            <button>Hey Now</button>
                        </div> */}
                    </div>
                        <OurWork link1="/projects/plants" link2="/projects/kasper" image = {HomeWork_Home.image} workTitle={HomeWork_Home.workTitle} workCaption={HomeWork_Home.workCaption}  image2 = {HomeWork_Home.image2} workTitle2={HomeWork_Home.workTitle2} workCaption2={HomeWork_Home.workCaption2}/>
                        <OurWork link1="/projects/arbre" link2="/projects/oneDegree" image = {HomeWork_Home.image3} workTitle={HomeWork_Home.workTitle3} workCaption={HomeWork_Home.workCaption3} image2 = {HomeWork_Home.image4} workTitle2={HomeWork_Home.workTitle4} workCaption2={HomeWork_Home.workCaption4}/>
                        <div className="btn-wrapper">
                            <Link to="/projects">
                                <Button text="View More"/>
                            </Link>
                            
                        </div>
                 </div>
                
            </div>
        </div>
    )
}


export default HomeWork
