import React, {useContext, useEffect} from 'react';
import Footer from '../Components/Footer' 
import HomeBlogs from '../Components/HomeBlogs'
import HomeHero from '../Components/HomeHero'
import HomeWork from '../Components/HomeWork'
import Navbar from '../Components/Navbar'
import gsap , {Power3, Power4} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import HomeBanner from '../Components/HomeBanner';
import { Helmet } from "react-helmet";


function Home() {

    gsap.registerPlugin(ScrollTrigger);

    useEffect(()=>{
     
      // The main page 

        gsap.fromTo('#home', {
          opacity: 0
        }, {
          opacity: 1
        })

        const rights = gsap.utils.toArray('.right');
        const rightsBlogs = gsap.utils.toArray('.right-blog')

        //Intro Animations Start

        // Actual Intro

        gsap.timeline().fromTo('#home-hero',{
          width: "0%",
          // delay: 0.2,
          opacity: 0,
          ease: Power3.easeIn
        }, {
          width: "100%",
          opacity: 1
        }).from(".hero__inner-content h1", {
          y:'350px',
          skewY: 17,
          delay: 0.2,
          stagger: {
            amount: 0.3
          }
        }).from(".frame-inner", {
          y: '15px',
          opacity: 0
        }).from(".logion-ver",{
          y: "-100%",
          opacity: 0
        }, 0.5)

          // The background animation on scroll
           
          gsap.timeline({
            scrollTrigger:{
              trigger: "#home-hero",
              scroller: "#root",
              start: 'top',
              scrub: 1.5
            }
          }).fromTo(".plus1", {
            y: '-30px'
          }, {
            y: '30px'
          });
          

          gsap.timeline({
            scrollTrigger:{
              trigger: "#home-hero",
              scroller: "#root",
              start: 'top',
              scrub: 1.5
            }
          }).fromTo(".plus2", {
            y: '-10px'
          }, {
            y: '10px'
          });

          gsap.timeline({
            scrollTrigger:{
              trigger: "#home-hero",
              scroller: "#root",
              start: 'top',
              scrub: 1.5
            }
          }).fromTo(".logion-ver", {
            y: '0px'
          }, {
            y: '-100px'
          });
          //Home Work Title TImeline

          var tht = gsap.timeline({
            scrollTrigger: {
              trigger: '.homework-inner__wrapper',
              scroller: '#root',
              start: 'top 80%',
              end: 'top 80%',
              }
          })

          tht.from('.homework-header .line h1',{
            y: '100px',
         
            skewY: 7,
            stagger: {
              amount: 0.3
            }
          }).from('.homework-header p', {
            opacity: 0,
            yPercent: '10'
          })

          //Work Section Animation

          var tow = gsap.timeline({
            scrollTrigger: {
              trigger: '#our-work',
              scroller: '#root',
              start: 'top 90%',
              end: 'top 90%',
              
            }
          })

          tow.from('.hw-wrapper__img', {
            opacity: 0,
            scale: 1.1,
            duration: 0.1,
            ease: Power3.easeOut
          }).to('.hw-wrapper__img > img', {
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            ease: Power4.easeOut,
            duration: 0.05
          })


          //The Banner

          var thb = gsap.timeline({
            scrollTrigger: {
              trigger:'.HomeServices-banner',
              scroller: '#root',
              start: 'top 90%',
              end: 'top 90%'
            }
          })

          thb.fromTo('.HomeServices-banner', 2,{
            clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0 100%)',
            ease: Power3.easeOut, duration: 0.5
          }, {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0 100%)',
            ease: Power3.easeOut,
            duration: 0.5
          })

          //The Blog Section

          gsap.timeline({
            scrollTrigger: {
              trigger: '.HB-wrapper',
              scroller: '#root',
              start: 'top 70%',
              end: 'top 70%'
            }
          }).from('.HB-title .line h1', {
            y: '130px',
           
            skewY: 7,
            stagger: {
              amount: 0.3
            }
          }).from('.HB-title > p', {
            opacity: 0,
            yPercent: '10'
          })
          
          gsap.timeline({
            scrollTrigger:{
              trigger: '.HB-wrapper',
              scroller: '#root',
              start: 'top 60%',
              end: 'top 60%',
            }
           
          }).from('.HB-inner__img', {
            opacity: 0,
            scale: 1.1,
            duration: 0.1,
          }).to('.HB-inner__img > img', {
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            ease: Power4.easeOut
          })
          
        //Intro Animations End

        ScrollTrigger.matchMedia({
          "(min-width: 1200px)": function(){
            rights.forEach(right=>{
            
              gsap.fromTo(right, {
                y: '100px',
                scrollTrigger: {
                  trigger: '#HomeWork',
                  scroller: '#root',
                  top: 'top 200px',
                  scrub: 1,
                  
                }
              }, {
                  y: '-100px',
                  scrollTrigger: {
                      trigger: '#HomeWork',
                      scroller: '#root',
                      top: 'top 200px',
                      scrub: 1,
                      
                    }
              })
          })

        rightsBlogs.forEach(right=>{
            
            gsap.fromTo(right, {
              y: '100px',
              scrollTrigger: {
                trigger: '#HomeBlogs',
                scroller: '#root',
                top: 'top 200px',
                scrub: 1.2,
                
              }
            }, {
                y: '-100px',
                scrollTrigger: {
                    trigger: '#HomeBlogs',
                    scroller: '#root',
                    top: 'top 200px',
                    scrub: 1.2,
                    
                  }
            })
        })
          }
        })
    

        //Blogs

      // The Color change
      

      gsap.timeline({
        scrollTrigger:{
          trigger: '#HomeWork',
          start: 'top',
          end: 'top',
          scroller: '#root',
          scrub: 1
        }
      }).to('.logo svg path',{

        fill: '#000'
                 
      }, 0).to('.header-links a p', {
        color: "#000"
      }, 0).to('.nav-toggler__span', {
        backgroundColor: "#000"
      }, 0)

      gsap.timeline({
        scrollTrigger:{
          trigger: '#footer',
          start: 'top',
          end: 'top',
          scroller: '#root',
          scrub: 1
        }
      }).to('.logo svg path',{
        fill: '#fff'
      }, 0).to('.header-links a p', {
        color: "#fff"
      }, 0).to('.nav-toggler__span', {
        backgroundColor: "#fff"
      }, 0)



      gsap.timeline({
        scrollTrigger:{
          trigger: '.HomeServices-banner',
          start: 'top',
          end: 'top',
          scroller: '#root',
          scrub: true
        }
      }).to('.logo svg path',{
        fill: '#fff'
      }, 0).to('.header-links a p', {
        color: "#fff"
      }, 0).to('.nav-toggler__span', {
        backgroundColor: "#fff"
      }, 0)


      gsap.timeline({
        scrollTrigger:{
          trigger: '#HomeBlogs',
          start: 'top',
          end: 'top',
          scroller: '#root',
          scrub: true
        }
      }).to('.logo svg path',{
        fill: '#000'
      }, 0).to('.header-links a p', {
        color: "#000"
      }, 0).to('.nav-toggler__span', {
        backgroundColor: "#000"
      }, 0)

    })



    return (
        <div id="home">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Logion Solutions is an award winning website development company and a digital agency based in Ahmedabad that believes in creating timeless apps"/>
                <meta name="keywords" content="Logion, Logion Solutions, Digital Agency, Digital Design, Website Development Company, Ahmedabad, App Development Company, Brading Agency, Timeless web apps and websites, Experience based"/>
                <title>Logion Solutions | Web Design | Website Development | Mobile Apps Development | Branding Company in Ahmedabad </title>
            </Helmet>
            <Navbar color="#fff" logoColor="#fff" />
            <HomeHero/>
            <HomeWork/>
            <HomeBanner/>
            <HomeBlogs/>
            <Footer/>
        </div>
    )
}

export default Home
