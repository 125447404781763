import React from 'react'
import './HomeBanner.scss'

function HomeBanner() {
    return (
        <div>
            <div className="HomeServices-banner">
                <div className="container">
                    <div className="HomeServices-inner">
                        <p>Digital Pioneers</p>
                        <h2>We are a web design and development company, building websites that drive traffic, engagement, and conversion for industry-leading brands and startups in India</h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner
