import React from 'react'
import './BlogsMain.scss'
import { Link } from 'react-router-dom'

function BlogsMain(props) {
    return (
        <div className="blogs-main">

            <div className="HB-wrapper">
                <div className="HB-main">
                    <div className="row">
                        <div className="col-12 col-md-6">

                            <Link to={props.link1}>
                                <div className="HB-main__inner">

                                    <div className="HB-inner__img">
                                    <img src={props.image1} alt="" />
                                    </div>


                                    <div className="HB-main__content">
                                        <h2>{props.title1}</h2>
                                        <p>{props.caption1}</p>
                                    </div>
                                </div>
                            </Link>
                            
                        </div>

                        <div className="col-12 col-md-6 right-blog">

                            <Link to={props.link2}>
                                <div className="HB-main__inner">
                                    <div className="HB-inner__img">
                                         <img src={props.image2} alt="" />
                                        
                                    </div>

                                    <div className="HB-main__content">
                                        <h2>{props.title2}</h2>
                                        <p>{props.caption2}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>

                    </div>  
                </div>  
            </div>
                      
        </div>
    )
}

export default BlogsMain
